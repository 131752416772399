import { Routes } from '@angular/router';
import { UserRole } from '../models/user.model';
import { roleMatcher } from '../auth/role.matcher';
import { homeOrLogin } from '../auth/home-or-login';
import { UserGuard } from '../../common/auth/user.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminUsersInfoComponent } from './admin-users/admin-users-info/admin-users-info.component';
const ɵ0 = {
    authUserMatcher: roleMatcher(UserRole.SuperUser),
    authFallbackUrl: homeOrLogin
};
const routes = [
    {
        path: 'admin',
        component: AdminDashboardComponent,
        canActivate: [UserGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: AdminUsersComponent
            },
            {
                path: ':id',
                component: AdminUsersInfoComponent,
            }
        ]
    }
];
export class AdminRoutingModule {
}
export { ɵ0 };
