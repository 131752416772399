import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
export class UserService {
    constructor(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    getUser(id) {
        return this.apiResourcesService.staff.find(id);
    }
    getUsers() {
        return this.apiResourcesService.staff.get();
    }
    createMasterUser(data) {
        return this.apiResourcesService.masterUser.create(data);
    }
    createUser(data) {
        return this.apiResourcesService.staff.create(data);
    }
    updateUser(data) {
        return this.apiResourcesService.auth.user.update(data);
    }
    deleteUser(id) {
        return this.apiResourcesService.staff.delete(id);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: UserService, providedIn: "root" });
