<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">

        <mat-form-field>
            <input matInput placeholder="Name" type="text" formControlName="name" required>
            <mat-error *errors="let message of form.get('name'); subject: 'name'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-progress-bar *ngIf="fieldProgressBarModes['currency_id']"
                          [mode]="fieldProgressBarModes['currency_id']"></mat-progress-bar>
        <mat-form-field *ngIf="!fieldProgressBarModes['currency_id']">
            <mat-label>Currency</mat-label>
            <mat-select formControlName="currency_id" required>
                <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                    {{currency.name}}
                </mat-option>
            </mat-select>
            <mat-error *errors="let message of form.get('currency_id'); subject: 'currency'">{{ message }}</mat-error>
        </mat-form-field>

        <ng-template [ngIf]="form.contains('parent_id')">
            <mat-progress-bar *ngIf="fieldProgressBarModes['parent_id']"
                              [mode]="fieldProgressBarModes['parent_id']"></mat-progress-bar>
            <mat-form-field *ngIf="!fieldProgressBarModes['parent_id']">
                <mat-label>Parent company</mat-label>
                <mat-select formControlName="parent_id" required>
                    <mat-option *ngFor="let parent of parents" [value]="parent.id">
                        {{parent.name}}
                    </mat-option>
                </mat-select>
                <mat-error *errors="let message of form.get('parent_id'); subject: 'parent_company'">{{ message
                  }}</mat-error>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="form.contains('managers_ids')">
            <mat-progress-bar *ngIf="fieldProgressBarModes['managers_ids']"
                              [mode]="fieldProgressBarModes['managers_ids']"></mat-progress-bar>

            <mat-form-field *ngIf="!fieldProgressBarModes['managers_ids']">
                <mat-select
                    placeholder="Manager"
                    formControlName="managers_ids"
                    multiple
                >
                    <mat-option *ngFor="let manager of managers" [value]="manager.id">
                        {{manager.fullName}}
                    </mat-option>
                </mat-select>

                <mat-error *errors="let message of form.get('managers_ids'); subject: 'manager'">{{ message }}</mat-error>
            </mat-form-field>


            <!--<mat-form-field *ngIf="!fieldProgressBarModes['managers_ids']">
            <input
              type="text"
              placeholder="Manager"
              matInput
              formControlName="managers_ids"
              [matAutocomplete]="auto"
              [multiple]='true'
              (input)="onSearchChange($event.target.value)"
              required
            >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let manager of managers"
                aria-activedescendant
                [value]="manager"
                (onSelectionChange)="selectedManager(manager)"
              >
                {{manager.fullName}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *errors="let message of form.get('managers_ids'); subject: 'manager'">{{ message }}</mat-error>
          </mat-form-field>-->

        </ng-template>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="!progressBarMode">
        <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">Save</button>
        <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
    </ng-container>
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
</mat-dialog-actions>
