import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material';
import { UserProfileComponent } from './user-profile.component';
import { Subscription } from 'rxjs';

@Component({
               selector: 'app-user-dashboard',
               templateUrl: './dashboard.component.html'
           })
export class DashboardComponent implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();

    user: UserModel;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected dialog: MatDialog
    ) {
    }

    ngOnInit(): void {

        this.subscriptions.add(this.authService.user.subscribe((user) => this.user = user));
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    profile(): void {

        // @todo need create common service to utilize such dialogs
        const dialogRef = this.dialog.open(UserProfileComponent, {
            width: '400px',
            data: this.user
        });

        dialogRef.afterClosed().subscribe((updatedUser) => updatedUser && this.user.fillFrom(updatedUser));
    }

    logout(): void {

        this.authService
            .revoke()
            .subscribe(() => this.router.navigate([ '/login' ]));
    }
}
