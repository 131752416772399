/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./companies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./companies-list/companies-list.component.ngfactory";
import * as i6 from "./companies-list/companies-list.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/common";
import * as i9 from "./companies.component";
import * as i10 from "../api/api-resources.service";
var styles_CompaniesComponent = [i0.styles];
var RenderType_CompaniesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompaniesComponent, data: {} });
export { RenderType_CompaniesComponent as RenderType_CompaniesComponent };
function View_CompaniesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.progressBarMode; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CompaniesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-companies-list", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.onAdd($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.onEdit($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.onDelete($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_CompaniesListComponent_0, i5.RenderType_CompaniesListComponent)), i1.ɵdid(1, 49152, null, 0, i6.CompaniesListComponent, [i7.MatDialog], { companies: [0, "companies"] }, { add: "add", edit: "edit", delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companies; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CompaniesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompaniesComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompaniesComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progressBarMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.companies.length; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CompaniesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-companies", [], null, null, null, View_CompaniesComponent_0, RenderType_CompaniesComponent)), i1.ɵdid(1, 114688, null, 0, i9.CompaniesComponent, [i10.ApiResourcesService, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompaniesComponentNgFactory = i1.ɵccf("app-companies", i9.CompaniesComponent, View_CompaniesComponent_Host_0, {}, {}, []);
export { CompaniesComponentNgFactory as CompaniesComponentNgFactory };
