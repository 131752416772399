<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="account_dialog">
  <form [formGroup]="form" fxLayout="column">

    <mat-form-field>
      <input matInput placeholder="Account code" formControlName="code" autocomplete="code" appOmitSpecialChar>
      <mat-error *errors="let message of form.get('code'); subject: 'code'">{{ message }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Account name" type="text" formControlName="name" required autocomplete="name">
      <mat-error *errors="let message of form.get('name'); subject: 'account_name'">{{ message }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Account type</mat-label>
      <mat-select formControlName="type" required>
        <mat-option
          *ngFor="let type of types" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
      <mat-error *errors="let message of form.get('type'); subject: 'type'">{{ message }}</mat-error>
    </mat-form-field>
      <mat-form-field>
          <input matInput placeholder="Local Mapping" type="text" formControlName="local_mapping" autocomplete="local_mapping">
          <mat-error *errors="let message of form.get('local_mapping'); subject: 'local Mapping'">{{ message }}</mat-error>
      </mat-form-field>
      <mat-form-field>
          <input matInput placeholder="Group Mapping" type="text" formControlName="group_mapping" autocomplete="group_mapping">
          <mat-error *errors="let message of form.get('group_mapping'); subject: 'group Mapping'">{{ message }}</mat-error>
      </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container>
    <button mat-raised-button color="primary" [disabled]="form.invalid || disableBtn" (click)="save()">Save</button>
    <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
  </ng-container>
</mat-dialog-actions>
