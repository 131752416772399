import { Injectable } from '@angular/core';
import { AuthService as CommonAuthService } from '../../common/auth/auth.service';
import { Observable } from 'rxjs';
import { AuthTokenModel } from '../models/auth-token.model';
import { UserModel } from '../models/user.model';
import { LocalStorage } from '../../common/local.storage';
import { Dictionary } from '../../common/types';
import { AccessModel } from '../models/access.model';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { map, switchMap } from 'rxjs/operators';
import { ApiResourcesService } from '../api/api-resources.service';

export interface AuthCredentials {

    email: string;
    password: string;
}

@Injectable()
export class AuthService extends CommonAuthService<AuthTokenModel, UserModel> {

    protected getUser(token: AuthTokenModel): Observable<UserModel> {

        return this.apiResourcesService.auth.user.first();
    }

    protected performAttempt(credentials: AuthCredentials): Observable<[ AuthTokenModel, UserModel ]> {

        return this.apiResourcesService.auth.access.create(credentials).pipe(map((access: AccessModel) => [ access.token, access.user ]));
    }

    protected performRevoke(token: AuthTokenModel): Observable<void> {

        return this.apiResourcesService.auth.access.delete();
    }

    protected createStorage(): void {

        this.storage = new LocalStorage<AuthTokenModel>('app:auth', (attributes?: Dictionary) => {

            if (attributes) {

                return new AuthTokenModel(attributes);
            }
        });
    }

    constructor(protected router: Router, protected apiService: ApiService, protected apiResourcesService: ApiResourcesService) {

        super();

        this.apiService.authTokenGetter = () => `${this.token}`;
        this.apiService
            .on(401)
            .pipe(switchMap(() => this.forget()))
            .subscribe(() => this.router.navigateByUrl('/login'));
    }
}