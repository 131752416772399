import { Observable, Subscription } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ControlsValidationErrors } from './types';

export interface ProxyTarget {
    controls: {
        [ key: string ]: AbstractControl;
    };
}

export class ExternalErrorsProxy {

    protected updateControlErrors(control: AbstractControl, errors?: ValidationErrors) {

        const controlErrors = control.errors || {};

        if (errors) {

            Object.keys(errors)
                  .forEach((key) => {

                      controlErrors[ `${this.prefix}${key}` ] = errors[ key ];
                  });
        } else {

            Object.keys(controlErrors)
                  .forEach((key) => {

                      if (key.startsWith(this.prefix)) {

                          delete controlErrors[ key ];
                      }
                  });
        }

        control.setErrors(Object.keys(controlErrors).length ? controlErrors : null);
    }

    protected updateControlsErrors(errors: ControlsValidationErrors): void {

        Object.keys(this.target.controls)
              .forEach((name) => this.updateControlErrors(this.target.controls[ name ], errors[ name ]));
    }

    constructor(
        protected source: Observable<ControlsValidationErrors>, protected target: ProxyTarget, protected prefix: string = 'external:') {
    }

    subscribe(): Subscription {

        return this.source
                   .subscribe((errors) => this.updateControlsErrors(errors));
    }
}
