import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { UiModule } from '../ui/ui.module';
import { MatDialogModule, MatInputModule, MatListModule, MatMenuModule, MatProgressBarModule } from '@angular/material';
import { UserProfileComponent } from './user-profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';

@NgModule({
              declarations: [
                  DashboardComponent,
                  UserProfileComponent
              ],
              entryComponents: [
                  UserProfileComponent
              ],
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  RouterModule,
                  FormsModule,
                  UiModule,
                  MatMenuModule,
                  MatListModule,
                  MatInputModule,
                  MatDialogModule,
                  MatProgressBarModule
              ],
              exports: [
                  DashboardComponent
              ]
          })
export class UserModule {
}
