import { AbstractControl, FormControl, ValidatorFn, Validators as NgValidators } from '@angular/forms';
import { Constructor, Dictionary } from '../types';
import { Subscription } from 'rxjs';

export interface ControlsDictionary extends Dictionary<AbstractControl> {
}

export class Validators extends NgValidators {

    static isEmptyControlValue(control: AbstractControl) {

        return control.value == null || control.value.length === 0;
    }

    static requiredIf(conditionFn: () => boolean): ValidatorFn {

        return (control: AbstractControl) => conditionFn() ? this.required(control) : null;
    }

    static equalTo(target: string | AbstractControl, targetName?: string): ValidatorFn {

        return (control: AbstractControl) => {

            if (this.isEmptyControlValue(control)) {

                return null;
            }

            if (typeof target === 'string' && !targetName) {

                targetName = target;
            }

            target = target instanceof AbstractControl ? target : control.parent.get(target);

            return control.value === target.value ? null : { equalTo: { target: targetName } };
        };
    }

    static confirmation(
        target: AbstractControl, targetName: string, subscription: Subscription,
        suffix: string = '_confirmation'
    ): [ string, AbstractControl ] {

        const validators = [
            this.requiredIf(() => !!target.value),
            this.equalTo(target, targetName)
        ];
        const control = new (target.constructor as Constructor<AbstractControl>)(null, validators);

        subscription.add(target.valueChanges.subscribe(() => control.updateValueAndValidity()));

        return [ `${targetName}${suffix}`, control ];
    }

    static withConfirmation(target: AbstractControl, targetName: string, subscription: Subscription, suffix?: string): ControlsDictionary {

        const [ name, control ] = this.confirmation(target, targetName, subscription, suffix);

        return {
            [ targetName ]: target,
            [ name ]: control
        };
    }

    static passworded(
        target: AbstractControl, targetName: string, subscription: Subscription, validators: ValidatorFn[] = [],
        suffix: string = '_passworded'
    ): [ string, AbstractControl ] {

        validators.unshift(this.requiredIf(() => !!target.value));

        const control = new FormControl(null, validators);

        subscription.add(target.valueChanges.subscribe(() => control.updateValueAndValidity()));

        return [ `${targetName}${suffix}`, control ];
    }

    static withPassworded(
        target: AbstractControl, targetName: string, subscription: Subscription, validators: ValidatorFn[] = [],
        suffix?: string
    ): ControlsDictionary {

        const [ name, control ] = this.passworded(target, targetName, subscription, validators, suffix);

        return {
            [ targetName ]: target,
            [ name ]: control
        };
    }
}
