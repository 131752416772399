import { BehaviorSubject, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { pipeFromArray } from 'rxjs/internal/util/pipe';
import { filter, first, tap } from 'rxjs/operators';
import { Getter } from '../../types';

/**
 * Checks if values emitted by the source BehaviorSubject must be refreshed or emitted as is
 *
 * This operator takes values from the source BehaviorSubject,
 * passes them through a `predicate` function and either
 * refresh and force emission again for those values that yielded `true`
 * or emits as is for those that yielded `false`.
 */
export function persistence<T>(predicate: (value: T) => boolean, getter: Getter<Observable<T>>): MonoTypeOperatorFunction<T> {

    return function persistenceOperatorFunction(source: BehaviorSubject<T>): Observable<T> {

        return pipeFromArray([
                                 tap((value: T) => {

                                     if (predicate(value)) {

                                         getter().pipe(first()).subscribe((newValue) => source.next(newValue));
                                     }
                                 }),
                                 filter((value: T) => !predicate(value))
                             ])(source);
    };
}