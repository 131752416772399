<mat-toolbar *ngIf="users && !users.length && users.length != 0" color="warn" fxLayoutAlign="space-around center">No users yet</mat-toolbar>

<table *ngIf="users && users.length" mat-table [dataSource]="users" class="mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>#ID</th>
    <td mat-cell *matCellDef="let user">{{user.id}}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let user">{{user.email}}</td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>Firts Name</th>
    <td mat-cell *matCellDef="let user">{{user.firstName}}</td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let user">{{user.lastName}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let user">
      <button type="button" mat-icon-button color="primary" (click)="detailReport(user)" *ngIf="users && users.length && showDeletedUsers">
        <mat-icon>remove_red_eye</mat-icon>
      </button>
      <button type="button" mat-icon-button (click)="onDelete(user)" color="warn" *ngIf="users && users.length && showDeletedUsers">
        <mat-icon>delete</mat-icon>
      </button>
      <button type="button" mat-icon-button color="primary" (click)="onRestore(user)" *ngIf="users && users.length && !showDeletedUsers">
        <mat-icon>restore</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
