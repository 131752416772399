import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiResourcesService } from '../../api/api-resources.service';
import * as moment from 'moment';
import { ReportType } from '../../models/report.model';
import { ApiService } from '../../api/api.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Validators } from '../../../common/forms/validators';
import { finalize } from 'rxjs/operators';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
               selector: 'app-upload-report-modal',
               templateUrl: './upload-report-modal.component.html',
               styleUrls: [ './upload-report-modal.component.scss' ]
           })
export class UploadReportModalComponent implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();
    messages: Array<any> = [];
    control: FormControl;
    progressBarMode: MatProgressBar['mode'] | void;
    companyId: number;

    constructor(
        protected dialogRef: MatDialogRef<UploadReportModalComponent>,
        protected apiResourcesService: ApiResourcesService,
        protected apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) data: UploadReportModalComponent
    ) {
        this.companyId = data.companyId;
    }

    ngOnInit(): void {
        this.control = new FormControl(null, [ Validators.required ]);

        this.subscriptions.add(this.apiService.on(422).subscribe(response => {
            this.messages = [];
            if (response.errors) {
                for (const key of Object.keys(response.errors)) {
                    for (const err of response.errors[ key ]) {
                        this.messages.push(err);
                    }
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    upload() {
        const file: File = this.control.value[ 0 ];
        const form = new FormData();
        form.append('accounts', file, 'report');

        this.progressBarMode = 'indeterminate';
        this.control.disable();

        this.apiResourcesService.reportBalance
            .whereId(moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Xlsx)
            .where('_method', 'PUT')
            .create(form)
            .pipe(finalize(() => {
                this.progressBarMode = undefined;
                this.control.enable();
            }))
            .subscribe(() => this.dialogRef.close());
    }

    cancel(): void {

        this.dialogRef.close();
    }
}
