<h2 mat-dialog-title>Upload report</h2>
<mat-dialog-content>
    <div>
        <label for="reportUpload" class="mat-raised-button mat-primary">Choose XLSX file</label>
        <input id="reportUpload" type="file" class="hidden" [formControl]="control">
    </div>

    <div *ngIf="messages.length" class="errors">
        <mat-error *ngFor="let error of messages">
            {{error}}
        </mat-error>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="!progressBarMode">
        <button mat-raised-button [disabled]="control.invalid" color="primary" (click)="upload()">Upload</button>
        <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
    </ng-container>
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
</mat-dialog-actions>
