import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { ReportChange, ReportModel, ReportType } from '../../models/report.model';
import { ApiResourcesService } from '../../api/api-resources.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import { ReportAccountModel } from '../../models/report.account.model';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../models/user.model';
import {Subscription} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {UploadReportModalComponent} from '../upload-report-modal/upload-report-modal.component';

@Component({
               selector: 'app-create-report',
               templateUrl: './create-report.component.html',
               styleUrls: [ './create-report.component.scss' ]
           })
export class CreateReportComponent implements OnInit, OnDestroy {
    protected subscriptions = new Subscription();
    progressBarMode: string;
    displayedColumns: string[] = [ 'id', 'code', 'name', 'type', 'balance' ];
    report: ReportModel;
    reportAccounts: Array<ReportAccountModel>;
    reportDiff: ReportChange[] = [];
    reportSum: ReportChange[] = [];
    validTotal: boolean;
    notCompanyManager = false;
    companyId: number;
    user: UserModel;

    form: FormGroup;

    get balancesArrayControl(): FormArray {

        return this.form.get('balancesArray') as FormArray;
    }

    protected createForm() {
        this.form = this.fb.group({
                                      balancesArray: this.fb.array([ this.createItem() ])
                                  });
    }

    constructor(
        private location: Location,
        protected apiResourcesService: ApiResourcesService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        protected dialog: MatDialog,
        private authService: AuthService
    ) {
        /*TODO: Check init form*/
        this.form = this.fb.group({
                                      balancesArray: this.fb.array([])
                                  });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.authService.user.subscribe((user) => this.user = user));

        this.activatedRoute.params.subscribe(params => {
            this.companyId = params['companyId'];
        });

        this.getReport();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    createItem(): FormGroup {
        return this.fb.group({
                                 balance: new FormControl()
                             });
    }

    getReport() {
        this.progressBarMode = 'query';
        this.apiResourcesService.reportShow
            .whereId( moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(() => (this.progressBarMode = null)))
            .subscribe((report: ReportModel) => {
                this.report = report;
                this.reportAccounts = report.accounts;

                for (let value of this.reportAccounts) {
                    this.reportSum.push({
                                            account_id: value.id,
                                            balance: Number(value.balance)
                                        });

                    this.reportDiff.push({
                        account_id: value.id,
                        balance: Number(value.balance)
                    });
                }

                this.getTotal();
                this.createForm();
            });
    }

    onBalanceChange(target, id: number) {
        let oldValueIndex;
        let oldReportValueIndex;
        let newBalance = target.value;

        if (isNaN(newBalance)) {
            newBalance = 0;
        }

        if (this.reportDiff) {
            oldValueIndex = this.reportDiff.findIndex(el => el.account_id === id);
        }
        if (oldValueIndex > 0 || oldValueIndex === 0) {
            this.reportDiff[ oldValueIndex ] = {
                account_id: id,
                balance: Number(newBalance)
            };
        } else {
            this.reportDiff.push({
                                     account_id: id,
                                     balance: Number(newBalance)
                                 });
        }

        if (this.reportSum) {
            oldReportValueIndex = this.reportSum.findIndex(el => el.account_id === id);
        }
        if (oldReportValueIndex > 0 || oldReportValueIndex === 0) {
            this.reportSum[ oldReportValueIndex ] = {
                account_id: id,
                balance: Number(newBalance)
            };
        } else {
            this.reportSum.push({
                                    account_id: id,
                                    balance: Number(newBalance)
                                });
        }

        this.getTotal();
    }

    openSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: [ 'warning_snackbar' ]
        });
    }

    save() {
        if (!this.validTotal) {
            this.openSnackBar('Total balance must be zero!');
        } else {
            this.apiResourcesService.reportBalance
                .where('type', ReportType.Json)
                .update({
                            accounts: this.reportDiff
                        },
                    moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId
                )
                .subscribe(() => {
                    this.getReport();
                    this.reportDiff = [];
                });
        }
    }

    getTotal() {
        let total = 0;
        for (let value of this.reportSum) {
            total += value.balance;
        }
        total === 0 ? this.validTotal = true : this.validTotal = false;

        if (isNaN(total)) {
            return '';
        } else {
            const fixTotal = total.toFixed(9);
            return parseFloat(fixTotal.toString());
        }
    }

    onFocusOut(event) {
        if (!event.target.value || !Number(+event.target.value)) {
            event.target.value = 0;
        }
    }

    goBack(): void {
        this.location.back();
    }

    download() {


        const link = this.apiResourcesService.reportShow
            .whereId( moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;

        window.open(link);
    }

    upload(): void {

        const dialogRef = this.dialog.open(UploadReportModalComponent, {
            width: '400px',
            data: {
                companyId: this.companyId
            }
        });

        dialogRef.afterClosed().subscribe();
    }

}
