import { NgModule } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';
import { ApiResourcesService } from './api-resources.service';

@NgModule({
              imports: [
                  HttpClientModule
              ],
              providers: [
                  ApiService,
                  ApiResourcesService
              ]
          })
export class ApiModule {
}
