<div class="container">
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
    <app-companies-list
      *ngIf="companies.length"
      [companies]="companies"
      (add)="onAdd($event)"
      (edit)="onEdit($event)"
      (delete)="onDelete($event)"
    ></app-companies-list>
</div>
