import { isFunction } from '../utils';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, first, map } from 'rxjs/operators';

export abstract class AuthGuard implements CanActivate, CanActivateChild {

    protected getAuthFallbackUrl(route: ActivatedRouteSnapshot): Observable<string> {

        return isFunction(route.data.authFallbackUrl)
               ? this.authService
                     .user
                     .pipe(
                         catchError(() => of(null)),
                         map((user) => route.data.authFallbackUrl(user))
                     )
               : of(`${route.data.authFallbackUrl}`);
    }

    protected navigate(url: string) {

        this.router.navigateByUrl(url);
    }

    protected abstract check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;

    constructor(protected router: Router, protected authService: AuthService<any, any>) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.check(route, state)
                   .pipe(map((isSatisfy: boolean) => {

                       if (!isSatisfy) {

                           this.getAuthFallbackUrl(route)
                               .pipe(first())
                               .subscribe((url) => this.navigate(url));
                       }

                       return isSatisfy;
                   }));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.canActivate(route, state);
    }
}