import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthModule as CommonAuthModule } from '../../common/auth/auth.module';

@NgModule({
              imports: [
                  CommonAuthModule
              ],
              providers: [
                  AuthService
              ]
          })
export class AuthModule {
}
