import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '../../../common/forms/validators';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiFormComponent } from '../../forms/api-form.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../api/api.service';
import { ApiResourcesService } from '../../api/api-resources.service';
import { AccountModel } from '../../models/account.model';

export interface AccountModifyDialogData {
  account: AccountModel;
  title?: string;
}

@Component({
  selector: 'app-account-modify',
  templateUrl: './account-modify.component.html',
  styleUrls: ['./account-modify.component.scss']
})
export class AccountModifyComponent extends ApiFormComponent {

  account: AccountModel;
  title: string;
  types = ['BS', 'PL'];
  disableBtn = false;

  get accountId(): number {

    return this.account && this.account.id;
  }

  get isNew(): boolean {

    return !this.accountId;
  }

  protected createForm(): FormGroup {


    const formGroup = new FormGroup({
        code: new FormControl(null, [
            Validators.required,
            Validators.maxLength(10)
        ]),
        name: new FormControl(null, [
            Validators.required,
            Validators.maxLength(50)
        ]),
        type: new FormControl(null, [
            Validators.required
        ]),
        local_mapping: new FormControl(null, [
            Validators.maxLength(50)
        ]),
        group_mapping: new FormControl(null, [
            Validators.maxLength(50)
        ])
    });
    return formGroup;
  }

  protected updateFormValue(): void {

    if (!this.isNew) {

      this.form.patchValue(this.account.toObject());
    }
  }

  constructor(
    protected dialogRef: MatDialogRef<AccountModifyComponent>,
    protected apiResourcesService: ApiResourcesService,
    apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) data: AccountModifyDialogData
  ) {

    super(apiService);

    this.account = data && data.account;
    this.title = data && data.title || (this.isNew ? 'Create account' : 'Edit account');
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.form.value.code = +this.form.value.code;
    this.disableBtn = true;

    this.withProgressBar(
      this.apiResourcesService.account[ this.isNew ? 'create' : 'update' ](this.form.value, this.accountId))
      .subscribe((account: AccountModel) => {
        this.dialogRef.close(account);
        this.disableBtn = false;
      }, error => {
          this.disableBtn = false;
      });
  }

}
