import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatTableDataSource, PageEvent} from '@angular/material';
import { UserModel } from '../models/user.model';
import { UserModifyComponent } from './user-modify/user-modify.component';
import { ApiResourcesService } from '../api/api-resources.service';
import {finalize} from 'rxjs/operators';
import {PaginatedItems} from '../../common/http/types';
import {AuthService} from '../auth/auth.service';
import {Subscription} from 'rxjs';

@Component({
   selector: 'app-users',
   templateUrl: './users.component.html',
   styleUrls: [ './users.component.scss' ]
})
export class UsersComponent implements OnInit, OnDestroy {
    protected subscriptions = new Subscription();

    progressBarMode: string;
    users: UserModel[];
    currentUser: UserModel;

    perPage = 20;
    pageIndex = 0;
    totalSize;
    dataSource: any;
    paginationVisibility = true;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(
        protected apiResourcesService: ApiResourcesService,
        protected authService: AuthService,
        protected dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
      this.reload();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    handlePage(e: PageEvent): void {
      this.pageIndex = e.pageIndex;
      this.perPage = e.pageSize;
      this.reload();
    }


    reload(): void {
        this.progressBarMode = 'query';
        this.subscriptions.add(
            this.apiResourcesService.staff
                .page(this.pageIndex + 1, this.perPage)
                .get()
                .pipe(finalize(() => this.progressBarMode = null))
                .subscribe((users: PaginatedItems<any>) => {
                this.users = users.data;
                this.dataSource = new MatTableDataSource<any>(this.users);
                this.dataSource.paginator = this.paginator;
                this.perPage = users.meta.per_page;
                this.totalSize = users.meta.total;
                this.pageIndex = users.meta.current_page - 1;
                this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
            })
        );
    }

    add(): void {
        this.subscriptions.add(this.authService.user.subscribe((user) => this.currentUser = user));

        const dialogRef = this.dialog.open(UserModifyComponent, {
            width: '400px',
            data: {isMaster: this.currentUser.isMaster},
            panelClass: 'create_user_modal'
        });
        dialogRef.afterClosed().subscribe((user?: UserModel) => {
          if (user) {
            this.users = [ ...this.users, user ];
            this.reload();
          }
        });

    }

    onDelete(user: UserModel): void {
        this.progressBarMode = 'indeterminate';
        this.subscriptions.add(
            this.apiResourcesService.staff
                .delete(user.id)
                .pipe(finalize(() => this.progressBarMode = null))
                .subscribe(() => {
                  this.users = this.users.filter((other) => !other.equal(user));
                  if ((this.totalSize - 1) <= this.perPage) {
                    this.pageIndex = 0;
                  }
                  this.reload();
                })
        );
    }
}
