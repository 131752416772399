import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService as CommonAuthService } from '../common/auth/auth.service';
import { AuthService } from './auth/auth.service';
import { ApiModule } from './api/api.module';
import { UiModule } from './ui/ui.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../common/forms/forms.module';
import { GuestModule } from './guest/guest.module';
import { CompaniesModule } from './companies/companies.module';
import { UsersModule } from './users/users.module';
import { MatDialogModule, MatListModule } from '@angular/material';
import { UserModule } from './user/user.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ReportsModule } from './reports/reports.module';
import { AccountsModule } from './accounts/accounts.module';
import { SharedModule } from './shared/shared.module';
import { VALIDATION_RENDERER_TEMPLATES_CONFIG } from '../common/forms/validation-renderer';
import { ValidationTemplates } from './forms/validation-templates';
import {AdminModule} from './admin/admin.module';

@NgModule({
              declarations: [
                  AppComponent
              ],
              imports: [
                  BrowserModule,
                  BrowserAnimationsModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  UiModule,
                  GuestModule,
                  UserModule,
                  CompaniesModule,
                  UsersModule,
                  AccountsModule,
                  ReportsModule,
                  AdminModule,
                  MatListModule,
                  MatDialogModule,
                  NotificationsModule,
                  SharedModule,
                  AppRoutingModule
              ],
              providers: [
                  { provide: CommonAuthService, useExisting: AuthService },
                  { provide: VALIDATION_RENDERER_TEMPLATES_CONFIG, useValue: ValidationTemplates }
              ],
              bootstrap: [ AppComponent ]
          })
export class AppModule {
}
