import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyModel} from '../../models/company.model';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-company-item',
  templateUrl: './company-item.component.html',
  styleUrls: ['./company-item.component.scss']
})
export class CompanyItemComponent implements OnInit {
  @Input() company: CompanyModel;
  @Input() companies: CompanyModel[];
  @Input() node;

  @Output()
  add = new EventEmitter<CompanyModel>();
  @Output()
  edit = new EventEmitter<CompanyModel>();
  @Output()
  delete = new EventEmitter<CompanyModel>();

  constructor(
    public dialog: MatDialog) {
  }

  ngOnInit() {}

  editCompany(company: CompanyModel): void {
    this.edit.emit(company);
  }

  addCompany(parent: CompanyModel): void {
    this.add.emit(parent);
  }

  deleteCompany(company): void {
    this.delete.emit(company);
  }

  isOwnerCompany() {
    return this.node.manager.id === this.node.ownerId;
  }
}
