import {Component, OnInit, ViewChild} from '@angular/core';
import { AccountModifyComponent } from './account-modify/account-modify.component';
import {MatDialog, MatPaginator, MatTableDataSource, PageEvent} from '@angular/material';
import { AccountModel } from '../models/account.model';
import { finalize } from 'rxjs/operators';
import { ApiResourcesService } from '../api/api-resources.service';
import {PaginatedItems} from '../../common/http/types';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

    progressBarMode: string;
    accounts: AccountModel[];

    perPage = 20;
    pageIndex = 0;
    totalSize;
    dataSource: any;
    paginationVisibility = true;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(
      protected dialog: MatDialog,
      protected apiResourcesService: ApiResourcesService,
    ) { }

    ngOnInit(): void {
      this.reload();
    }

    handlePage(e: PageEvent): void {
      this.pageIndex = e.pageIndex;
      this.perPage = e.pageSize;
      this.reload();
    }

    reload(): void {
      this.progressBarMode = 'query';

      this.apiResourcesService.account
        .page(this.pageIndex + 1, this.perPage)
        .get()
        .pipe(finalize(() => this.progressBarMode = null))
        .subscribe((accounts: PaginatedItems<any>) => {
          this.accounts = accounts.data;
          this.dataSource = new MatTableDataSource<any>(this.accounts);
          this.dataSource.paginator = this.paginator;
          this.perPage = accounts.meta.per_page;
          this.totalSize = accounts.meta.total;
          this.pageIndex = accounts.meta.current_page - 1;

          this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
        });
    }

    add(): void {
        const dialogRef = this.dialog.open(AccountModifyComponent, {
            width: '400px'
        });

        dialogRef.afterClosed().subscribe((account?: AccountModel) => {
          if (account) {
            this.accounts = [ ...this.accounts, account ];
            this.reload();
          }
        });
    }

    onEdit(account: AccountModel): void {

        const dialogRef = this.dialog.open(AccountModifyComponent, {
            width: '400px',
            data: { account }
        });

        dialogRef.afterClosed().subscribe((updatedAccount?) => updatedAccount && account.fillFrom(updatedAccount));
    }

    onDelete(account: AccountModel): void {

        this.progressBarMode = 'indeterminate';

        this.apiResourcesService.account
            .delete(account.id)
            .pipe(finalize(() => this.progressBarMode = null))
            .subscribe(() => {
              this.accounts = this.accounts.filter((other) => !other.equal(account));

              if ((this.totalSize - 1) <= this.perPage) {
                this.pageIndex = 0;
              }
              this.reload();
            });
    }

}
