import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { UserModel } from './user.model';
import { AuthTokenModel } from './auth-token.model';

export class AccessModel extends BaseModel {

    static get idName(): string {

        return 'token';
    }

    @Property({
                  typeOrFactory: UserModel
              })
    user: UserModel;

    @Property({
                  typeOrFactory: AuthTokenModel
              })
    token: AuthTokenModel;
}
