import { UserModel } from '../models/user.model';
import { Component, Inject } from '@angular/core';
import { ApiFormComponent } from '../forms/api-form.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '../../common/forms/validators';
import { Validations } from '../forms/validations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiResourcesService } from '../api/api-resources.service';
import { ApiService } from '../api/api.service';
import { omit } from 'lodash';

@Component({
               selector: 'app-user-profile',
               templateUrl: './user-profile.component.html',
               styleUrls: [ './user-profile.component.scss' ]
           })
export class UserProfileComponent extends ApiFormComponent {

    protected createForm(): FormGroup {

        const formGroup = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                ...Validations.getEmailRules()
            ]),
            first_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            last_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            ...Validators.withConfirmation(
                new FormControl(null, [
                    ...Validations.getPasswordRules()
                ]), 'password', this.subscriptions
            )
        });

        formGroup.addControl(
            ...Validators.passworded(formGroup.get('password'), 'password', this.subscriptions, Validations.getPasswordRules()));

        return formGroup;
    }

    protected updateFormValue(): void {

        this.form.patchValue(this.user.toObject());
    }

    protected get updateValue(): object {

        const value = this.form.value;

        if (!value.password) {

            return omit(value, [ 'password', 'password_confirmation', 'password_passworded' ]);
        }

        return value;
    }

    constructor(
        protected dialogRef: MatDialogRef<UserProfileComponent>,
        protected apiResourcesService: ApiResourcesService,
        apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) protected user: UserModel
    ) {

        super(apiService);
    }

    cancel(): void {

        this.dialogRef.close();
    }

    save() {

        this.withProgressBar(this.apiResourcesService.auth.user.update(this.updateValue))
          .subscribe((user: UserModel) => this.dialogRef.close(user));
    }
}
