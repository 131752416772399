import { Observable } from 'rxjs';
import { Dictionary } from '../types';
import { Resource } from './resource';

export enum ResourceAction {
    Get,
    Create,
    Update,
    Delete
}

export type ResourceActionScope<T> = (self: T) => void;

export enum ResponseType {
    Json = 'json'
}

export interface RawParams {
    [ param: string ]: string | string[];
}

export interface ResourceQueryParamNames {
    skip: string;
    take: string;
    page: string;
    pageSize: string;
    order: string;
}

export type RawHeaders = RawParams;

export interface RequestParams {
    path?: RawParams;
    query?: RawParams;
}

export interface RequestOptions {
    headers?: RawHeaders;
    params?: RawParams;
    responseType?: ResponseType;
}

export interface PaginationInfo {
  meta: MetaPaginationInfo;
}

export interface MetaPaginationInfo {
  total: number;
  last_page: number;
  current_page: number;
  per_page: number;
}

export interface PaginatedItems<T> extends PaginationInfo {
  data: T[];
}

export interface RestClient {

    endpoint: string;

    url(path: string, params?: RawParams): string;

    get<T>(path: string, options?: RequestOptions): Observable<Array<T> | PaginatedItems<T>>;

    post<T>(path: string, body: any | null, options?: RequestOptions): Observable<T>;

    put<T>(path: string, body: any | null, options?: RequestOptions): Observable<T | void>;

    delete(path: string, options?: RequestOptions): Observable<void>;
}
