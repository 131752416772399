import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationRenderer } from './validation-renderer';
import { ErrorsDirective } from './errors.directive';
import { OmitSpecialCharDirective } from './omit-special-char.directive';
import { FileControlValueAccessorDirective } from './file-control-value-accessor.directive';


@NgModule({
              imports: [ CommonModule ],
              declarations: [
                  ErrorsDirective,
                  OmitSpecialCharDirective,
                  FileControlValueAccessorDirective
              ],
              providers: [
                  ValidationRenderer
              ],
              exports: [
                  ErrorsDirective,
                  OmitSpecialCharDirective,
                  FileControlValueAccessorDirective
              ]
          })
export class FormsModule {
}
