import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from '../api/api.service';
import { finalize } from 'rxjs/operators';

export abstract class ApiFormComponent implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();

    form: FormGroup;
    progressBarMode: string;

    protected abstract createForm(): FormGroup;

    protected subscribeOnErrors(form: FormGroup): void {

        this.subscriptions.add(this.apiService.proxyValidation(form).subscribe());
    }

    protected updateFormValue(): void {
    }

    protected withProgressBar<T extends Observable<any>>(subject: T): T {

        this.progressBarMode = 'indeterminate';

        return subject.pipe(finalize(() => this.progressBarMode = null)) as T;
    }

    constructor(protected apiService: ApiService) {
    }

    ngOnInit() {

        this.form = this.createForm();
        this.subscribeOnErrors(this.form);
        this.updateFormValue();
    }

    ngOnDestroy() {

        this.subscriptions.unsubscribe();
    }
}
