export function handleHttpErrorResponse(response: { status: number }): boolean {

    const specificHandlerName = `handle${response.status}Response`;

    if (typeof this[ specificHandlerName ] === 'function') {

        return !!this[ specificHandlerName ](response);
    }

    return false;
}

export function trimSlashes(str: string) {

    return str.replace(/^\/+|\/+$/g, '');
}
