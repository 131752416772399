import { Injectable } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class GuestGuard extends AuthGuard {

    protected check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authService.guest();
    }

    constructor(router: Router, authService: AuthService<any, any>) {

        super(router, authService);
    }
}