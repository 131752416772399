import { Fabricator, Factory } from './types';

export class BaseFactory<T> implements Factory<T> {

    constructor(protected fabricator: Fabricator<T>) {
    }

    create(...args: any[]): T {

        return this.fabricator(...args);
    }
}
