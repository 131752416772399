import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CompaniesComponent } from './companies.component';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { UserRole } from '../models/user.model';
import { homeOrLogin } from '../auth/home-or-login';
import { roleMatcher } from '../auth/role.matcher';

const routes: Routes = [
    {
        path: 'companies',
        component: DashboardComponent,
        canActivate: [ UserGuard ],
        data: {
            authUserMatcher: roleMatcher(UserRole.MasterUser),
            authFallbackUrl: homeOrLogin
        },
        children: [
            {
                path: '',
                component: CompaniesComponent,
            },
        ]
    }
];

@NgModule({
              imports: [
                  RouterModule.forChild(routes)
              ],
              exports: [ RouterModule ]
          })
export class CompaniesRoutingModule {
}
