import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { UserRole } from '../models/user.model';
import { homeOrLogin } from '../auth/home-or-login';
import { roleMatcher } from '../auth/role.matcher';
import {AdjustmentComponent} from '../adjustment/adjustment.component';
import {CurrencyComponent} from '../currency/currency.component';

const routes: Routes = [
    {
        path: 'adjustment',
        component: DashboardComponent,
        canActivate: [ UserGuard ],
        data: {
            authUserMatcher: roleMatcher(UserRole.MasterUser),
            authFallbackUrl: homeOrLogin
        },
        children: [
            {
                path: '',
                component: AdjustmentComponent,
            },
        ]
    },
    {
        path: 'currency',
        component: DashboardComponent,
        canActivate: [ UserGuard ],
        data: {
            authUserMatcher: roleMatcher(UserRole.MasterUser),
            authFallbackUrl: homeOrLogin
        },
        children: [
            {
                path: '',
                component: CurrencyComponent,
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [ RouterModule ]
})
export class UiRoutingModule {
}
