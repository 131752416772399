import * as i from 'inflect';
import { Constructor, Factory, PrimitiveConstructor } from '../types';
import { isConstructor, isPresent, valueToType } from '../utils';
import { factory as createFactory } from '../factory';

export interface PropertyDecoratorOptions {

    get?: string;
    set?: string;
    sourceName?: string;
    typeOrFactory?: PrimitiveConstructor | Constructor<any> | Factory<any>;
    isArray?: boolean;
}

export function propertyFactoryGetter(property: string): string {

    return `get${i.camelize(i.underscore(property))}Factory`;
}

export function Property(options?: PropertyDecoratorOptions): PropertyDecorator {

    return (target, property: string) => {

        const { get, set, sourceName, typeOrFactory, isArray } = Object.assign({
                                                                                   get: 'get',
                                                                                   set: 'set',
                                                                                   sourceName: property
                                                                               }, options);

        if (isPresent(typeOrFactory)) {

            Object.defineProperty(target, propertyFactoryGetter(property), {
                get() {

                    const factory = isConstructor(typeOrFactory)
                                    ? createFactory((value) => valueToType(value, typeOrFactory))
                                    : typeOrFactory as Factory<any>;

                    return isArray
                           ? (value: any) => Array.isArray(value) && value.length ? value.map((item) => factory.create(item)) : []
                           : (value: any) => factory.create(value);
                },
                enumerable: false,
                configurable: true
            });
        }

        // @todo this set getter/setter as enumerable only on class, not on instance
        Object.defineProperty(target, property, {
            get() {

                return this[ get ](sourceName);
            },
            set(value) {

                this[ set ](sourceName, value);
            },
            enumerable: true,
            configurable: true
        });
    };
}
