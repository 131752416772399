import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ReportModel, ReportType } from '../../models/report.model';
import { ApiResourcesService } from '../../api/api-resources.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {UserModel} from '../../models/user.model';

@Component({
               selector: 'app-reports-list',
               templateUrl: './reports-list.component.html',
               styleUrls: [ './reports-list.component.scss' ]
           })
export class ReportsListComponent implements OnInit, OnDestroy {
    protected subscriptions = new Subscription();
    displayedColumns: string[] = [ 'date', 'company' ];
    @Input()
    user: UserModel;
    @Input()
    reports: ReportModel[];
    @Input()
    companyId: number;
    isMaster: boolean;
    disclaimer: string;

    constructor(
        protected dialog: MatDialog,
        protected apiResourcesService: ApiResourcesService,
        protected authService: AuthService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.authService.user.subscribe((user) => {
            this.isMaster = user.isMaster;
            if (user && user.isMaster) {
                this.disclaimer = 'disclaimer';
                this.displayedColumns.push('actions');
            } else {
                this.disclaimer = '';
                const i = this.displayedColumns.indexOf('actions');
                if (i !== -1) {
                    this.displayedColumns.splice(i, 1);
                }
            }
        }));
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    detailReport(report: ReportModel): void {
      this.router.navigate(['reports/details/', report.companyId, report.date]).then(r => {});
    }

    download(report: ReportModel): void {
        const link = this.apiResourcesService.reportShow
          .whereId(report.date + '/' + report.companyId)
          .where('type', ReportType.Xlsx)
          .authorizeUrl().url;
        window.open(link);
    }

    downloadRates() {
        const link = this.apiResourcesService.currencyRates
            .whereId(moment().year())
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;
        window.open(link);
    }

    downloadConsolidatedReport() {
        const link = this.apiResourcesService.reportSummaryRecord
            .whereId(moment().subtract(1, 'month').format('YYYY-MM'))
            .where('type', ReportType.XlsxConsolidated)
            .authorizeUrl().url;
        window.open(link);
    }

    downloadLocalReport() {
        const link = this.apiResourcesService.reportSummaryRecord
            .whereId(moment().subtract(1, 'month').format('YYYY-MM'))
            .where('type', ReportType.XlsxLocalCurrency)
            .authorizeUrl().url;
        window.open(link);
    }
}
