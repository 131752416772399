<div class="container">
  <mat-toolbar>
    <button
      class="btn"
      mat-raised-button
      color="primary"
      (click)="goBack()"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

      <div class="date_wrapper">
          <mat-form-field>
              <mat-label>Choose a date</mat-label>
              <input
                  matInput
                  [matDatepicker]="dp"
                  [formControl]="formDate"
                  [max]="tomorrow"
              >
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker
                  #dp
                  startView="multi-year"
                  (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, dp)"
                  (closed)="closePicker()"
                  panelClass="month-picker"
                  disabled="false"
                  color="primary"
              >
              </mat-datepicker>
          </mat-form-field>
      </div>

    <span fxFlex="1 1 auto"></span>
    <button [disabled]="!reportsArray || reportsArray.length === 0" type="button" mat-icon-button (click)="download()" color="primary">
      <mat-icon>cloud_download</mat-icon>
    </button>

  </mat-toolbar>
  <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

  <mat-toolbar *ngIf="reportsArray && !reportsArray.length" color="warn" fxLayoutAlign="space-around center">No reports yet</mat-toolbar>

  <div id="table_wrapper">
    <div class="accounts">
      <table *ngIf="accounts && accounts.length" mat-table [dataSource]="accounts">

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Account code</th>
          <td mat-cell *matCellDef="let account">{{account.accountCode}}</td>
            <td mat-footer-cell *matFooterCellDef><b>CTR</b></td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Account name</th>
          <td mat-cell *matCellDef="let account">{{account.accountName}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Account type</th>
          <td mat-cell *matCellDef="let account">{{account.accountType}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

      </table>
    </div>

    <div *ngFor="let el of reportsArray;let i = index;">
      <table mat-table [dataSource]="el.accounts">

        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef> {{displayedDynamicColumns[i]}} <br> ({{el.currency}}) </th>
          <td mat-cell *matCellDef="let element; let i = index;">
              {{element.balance}}
          </td>

            <td mat-footer-cell *matFooterCellDef> {{el.ctr | number}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['balance']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['balance'];"></tr>
        <tr mat-footer-row *matFooterRowDef="['balance']"></tr>

      </table>
    </div>

    <div>
      <table *ngIf="total && total.length" mat-table [dataSource]="total">

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total <br> ({{targetCurrency}})</th>
            <td mat-cell *matCellDef="let el">{{el.balance | number}}</td>
            <td mat-footer-cell *matFooterCellDef>{{totalCtr | number}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['total']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['total'];"></tr>
        <tr mat-footer-row *matFooterRowDef="['total']"></tr>

      </table>
    </div>
  </div>

</div>
