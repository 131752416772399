import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {ReportModel, ReportType, TotalModel, TotalReportModel} from '../../models/report.model';
import {MatDialog, MatPaginator} from '@angular/material';
import {ApiResourcesService} from '../../api/api-resources.service';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {PaginatedItems} from '../../../common/http/types';
import {ReportAccountModel} from '../../models/report.account.model';
import * as moment from 'moment';
import {FormControl} from "@angular/forms";
import {Moment} from "moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {MY_FORMATS} from "../reports.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';


@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class GeneralReportComponent implements OnInit {

  reports: PaginatedItems<any>;
  reportsArray: any[];
  progressBarMode: string;

  displayedColumns: string[] = [ 'code', 'name', 'type' ];
  displayedDynamicColumns: string[] = [];
  accounts: ReportAccountModel[] = [];
  total: TotalModel[];
  totalCtr = 0;

  targetCurrency: string;

  perPage = 100;
  pageIndex = 0;
  totalSize;
  dataSource: any;
  paginationVisibility = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  protected subscriptions = new Subscription();

    date = new FormControl({value: moment(), disabled: true});
    formDate = new FormControl({value: '', disabled: true});
    tomorrow = new Date();

  constructor(
    protected dialog: MatDialog,
    protected apiResourcesService: ApiResourcesService,
    private router: Router,
    private location: Location
  ) {
      this.tomorrow.setDate(this.tomorrow.getDate());
  }

  ngOnInit() {
    this.getSummaryReportData(moment().subtract(1, 'month').format('YYYY-MM'));
    this.formDate.setValue(moment().subtract(1, 'month').format('YYYY-MM'));
  }

  getSummaryReportData(data) {
    this.progressBarMode = 'query';
    this.apiResourcesService.reportSummaryRecord
      .whereId(data)
      .where('type', ReportType.Json)
      .first()
      .pipe(finalize(() => {
        this.progressBarMode = null;
      }))
      .subscribe((totalReport: TotalReportModel) => {
        if (totalReport.reports) {
          this.reportsArray = totalReport.reports;

          for (let report of this.reportsArray) {
              for (let acc of report.accounts) {
                  let index = this.accounts.findIndex(x => x.id === acc.id);
                  if (index === -1) {
                      this.accounts.push(acc);
                  }
              }
          }

          this.total = totalReport.total;
          this.targetCurrency = this.reportsArray[0].targetCurrency;

          for (const report of this.reportsArray) {
            this.displayedDynamicColumns.push(report.company.name);
            this.totalCtr += report.ctr;
          }

          this.accounts.sort((a, b) => {
              return (a.id - b.id);
          });

            for (let report of this.reportsArray) {
                for (let acc of this.accounts) {
                    let index = report.accounts.findIndex(x => x.id === acc.id);
                    if (index === -1) {
                        let faceAcc = {
                            id: acc.id,
                            balance: 0
                        };
                        report.accounts.push(faceAcc);
                    }
                }
                report.accounts.sort(function(a, b) {
                    return (a.id - b.id);
                });
            }
        }
      });
  }

  download(): void {

    const link = this.apiResourcesService.reportSummaryRecord
      //.whereId(moment().subtract(1, 'month').format('YYYY-MM'))
        .whereId(this.formDate.value)
      .where('type', ReportType.Xlsx)
      .authorizeUrl().url;
    window.open(link);
  }

  goBack(): void {
    this.location.back();
  }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    }
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));

        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(ctrlValue.format('YYYY-MM'));
        datepicker.close();
    }

    closePicker() {
        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(this.formDate.value);
    }

}
