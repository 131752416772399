<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <form [formGroup]="form" fxLayout="column">

    <mat-form-field>
      <input matInput placeholder="Email" type="email" formControlName="email" required autocomplete="new-email">
      <mat-error *errors="let message of form.get('email'); subject: 'email'">{{ message }}</mat-error>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="!progressBarMode">
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">Invite</button>
    <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
  </ng-container>
  <mat-progress-bar *ngIf="progressBarMode"  [mode]="progressBarMode"></mat-progress-bar>
</mat-dialog-actions>
