import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { PaginatedItems, RawParams, RequestOptions, RestClient } from '../../common/http/types';
import { catchError, filter, map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { MessageError, ValidationError } from './types';
import { ExternalErrorsProxy, ProxyTarget } from '../../common/forms/external-errors-proxy';
import { ControlsValidationErrors } from '../../common/forms/types';
import { NotificationsService } from '../notifications/notifications.service';
import { trimSlashes } from '../../common/http/utils';

@Injectable()
export class ApiService implements RestClient {

    protected httpErrors = new EventEmitter<HttpErrorResponse>();

    protected get errorHandler(): (error: any) => Observable<never> {

        return (error: any) => this.handleError(error);
    }

    protected get errorSuppressor(): (error: any) => Observable<boolean> {

        return (error: any) => this.suppressError(error);
    }

    authTokenGetter: () => string;

    get endpoint(): string {

        return environment.apiEndpoint;
    }

    protected handleHttpErrorResponse(response: HttpErrorResponse): void {
        this.httpErrors.emit(response);
        const errors = [];
        if (response.error.errors) {
            const errArray = Object.values(response.error.errors);
            errArray.forEach((msg) => {
                errors.push(msg);
            });
        } else if (response.error.messages) {
            errors.push(response.error.messages);
        }
        this.notificationsService.show(errors.join("\r\n") || response.error.message);
    }

    protected handleError(error: HttpErrorResponse | any): Observable<never> {


        if (error instanceof HttpErrorResponse) {

            this.handleHttpErrorResponse(error);
        } else {

            if (!error.message) {

                error.message = 'Server error';
            }

            this.notificationsService.show(error.message);
        }

        return throwError(error);
    }

    protected suppressError(error: any): Observable<boolean> {

        console.error(error);

        return of(false);
    }

    protected withQuery(url: string, params: RawParams): string {

        const query = (new HttpParams({ fromObject: params })).toString();

        if (query.length > 0) {

            const qIdx = url.indexOf('?');
            const sep = qIdx === -1 ? '?' : (qIdx < url.length - 1 ? '&' : '');

            url += sep + query;
        }

        return url;
    }

    constructor(protected httpClient: HttpClient, protected notificationsService: NotificationsService) {

        this.authTokenGetter = () => '';
    }

    url(path: string, params?: RawParams): string {

        const url = `${this.endpoint}/${trimSlashes(path)}`;

        return params ? this.withQuery(url, params) : url;
    }

    get<T>(path: string, options?: RequestOptions): Observable<Array<T> | PaginatedItems<T>> {

        return this.httpClient
                   .get<Array<T> | PaginatedItems<T>>(this.url(path), options)
                   .pipe(catchError(this.errorHandler));
    }

    post<T>(path: string, body: any | null, options?: RequestOptions): Observable<T> {

        return this.httpClient
                   .post<T>(this.url(path), body, options)
                   .pipe(catchError(this.errorHandler));
    }

    put<T>(path: string, body: any | null, options?: RequestOptions): Observable<T | void> {

        return this.httpClient
                   .put<T>(this.url(path), body, options)
                   .pipe(catchError(this.errorHandler));
    }

    delete(path: string, options?: RequestOptions): Observable<void> {

        return this.httpClient
                   .delete<void>(this.url(path), options)
                   .pipe(catchError(this.errorHandler));
    }

    on(status: 401 | 403): Observable<MessageError>;
    on(status: 422): Observable<ValidationError>;
    on(status: number): Observable<any> {

        return this.httpErrors
                   .pipe(
                       filter((response: HttpErrorResponse) => response.status === status),
                       map((response: HttpErrorResponse) => response.error)
                   );
    }

    proxyValidation(target: ProxyTarget): ExternalErrorsProxy {

        return new ExternalErrorsProxy(
            this.on(422)
                .pipe(map<ValidationError, ControlsValidationErrors>(
                    ({ errors }) => Object.keys(errors)
                                          .reduce((controlsErrors, field) => {

                                              controlsErrors[ field ] = errors[ field ].reduce((fieldErrors, message, i) => {

                                                  fieldErrors[ i ] = { message };
                                                  return fieldErrors;
                                              }, {});

                                              return controlsErrors;
                                          }, {})
                )),
            target,
            'app:api:'
        );
    }
}
