import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UserModel } from '../../models/user.model';
import { APPLY_ACTION, SimpleActionModalComponent } from '../../shared/simple-action-modal/simple-action-modal.component';
import {MatDialog} from '@angular/material';
import {ApiResourcesService} from '../../api/api-resources.service';

@Component({
   selector: 'app-users-list',
   templateUrl: './users-list.component.html',
   styleUrls: [ './users-list.component.scss' ]
})
export class UsersListComponent {

    displayedColumns: string[] = [ 'id', 'email', 'fullName', 'actions' ];

    @Input()
    users: UserModel[];

    /*@Output()
    edit = new EventEmitter<UserModel>();*/

    @Output()
    delete = new EventEmitter<UserModel>();

    constructor(
      protected apiResourcesService: ApiResourcesService,
      protected dialog: MatDialog
    ) {

    }

    onDelete(user: UserModel): void {

        const dialogRef = this.dialog.open(SimpleActionModalComponent, {
            width: '400px',
            data: { message: `Are you sure you want to delete user ${user.fullName}?` }
        });

        dialogRef.afterClosed().subscribe((action) => {
            if (action === APPLY_ACTION) {
              this.delete.emit(user);
            }
        });
    }
}
