
<mat-toolbar>
    <button mat-raised-button
          color="primary"
          (click)="goBack()">
        <mat-icon>keyboard_backspace</mat-icon>
        Back
    </button>

    <ng-template [ngIf]="user.isStaff">
        <span fxFlex="1 1 auto"></span>
        <button
            mat-raised-button
            color="primary"
            [disabled]="notCompanyManager"
            (click)="download()"
        >
            Download XLSX
        </button>
        <button
            mat-raised-button
            color="primary"
            [disabled]="notCompanyManager"
            (click)="upload()"
        >
            Upload XLSX
        </button>
    </ng-template>

</mat-toolbar>

<mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
<mat-toolbar *ngIf="reportAccounts && !reportAccounts.length" color="warn" fxLayoutAlign="space-around center">
  No accounts yet
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()" fxLayout="column">

  <table *ngIf="reportAccounts && reportAccounts.length" mat-table [dataSource]="reportAccounts">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Acount ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Account code</th>
        <td mat-cell *matCellDef="let element">{{element.accountCode}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Account name</th>
        <td mat-cell *matCellDef="let element">{{element.accountName}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Account type</th>
        <td mat-cell *matCellDef="let element">{{element.accountType}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>Balance</th>

        <td mat-cell *matCellDef="let element">
            <div formArrayName="balancesArray"
                 *ngFor="let item of balancesArrayControl.controls; let i = index;">
              <div [formGroupName]="i">
                <mat-form-field>
                  <input
                    matInput
                    appOnlyDigits
                    formControlName="balance"
                    value="{{element.balance}}"
                    autocomplete="off"
                    (keyup)="onBalanceChange($event.target, element.id)"
                    (focusout)="onFocusOut($event)"
                  >
                </mat-form-field>
              </div>
            </div>
        </td>

        <td mat-footer-cell *matFooterCellDef>
          <div
            [ngClass]="validTotal? 'primary' : 'warning'"
          >
            <b>Total :</b>  {{getTotal()}}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <div class="save-button-wrap">
    <button mat-raised-button
      color="primary"
      [disabled]="!reportDiff?.length"
    >
      Save
    </button>
  </div>
</form>

