import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface ISimpleActionDialogData {
  title?: string;
  message: string;
  applyText?: string;
  cancelText?: string;
}

export const CANCEL_ACTION = 'cancel';
export const DEFAULT_ACTION = 'default';
export const APPLY_ACTION = 'apply';

@Component({
  selector: 'app-simple-action-modal',
  templateUrl: './simple-action-modal.component.html',
  styleUrls: ['./simple-action-modal.component.scss']
})
export class SimpleActionModalComponent {

  constructor(
    public dialogRef: MatDialogRef<SimpleActionModalComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: ISimpleActionDialogData) {
  }

  onCancelClick(): void {
    this.dialogRef.close(CANCEL_ACTION);
  }

  onApplyClick(): void {
    this.dialogRef.close(APPLY_ACTION);
  }

}
