import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as NgFormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import {
    MatCardModule,
    MatDialogModule,
    MatDividerModule, MatFormFieldModule,
    MatGridListModule, MatInputModule,
    MatListModule, MatPaginatorModule, MatProgressBarModule,
    MatSelectModule,
    MatTableModule
} from '@angular/material';
import { UiModule } from '../ui/ui.module';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { CreateReportComponent } from './create-report/create-report.component';
import { SharedModule } from '../shared/shared.module';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { UploadReportModalComponent } from './upload-report-modal/upload-report-modal.component';
import { ReportsDetailComponent } from './reports-detail/reports-detail.component';
import { GeneralReportComponent } from './general-report/general-report.component';
import { FormsModule } from '../../common/forms/forms.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from "@angular/material/core";

@NgModule({
              imports: [
                  SharedModule,
                  CommonModule,
                  ReactiveFormsModule,
                  NgFormsModule,
                  AuthModule,
                  ApiModule,
                  ReportsRoutingModule,
                  MatDividerModule,
                  MatDialogModule,
                  MatListModule,
                  MatSelectModule,
                  UiModule,
                  MatTableModule,
                  MatGridListModule,
                  MatCardModule,
                  MatProgressBarModule,
                  MatFormFieldModule,
                  MatInputModule,
                  MatPaginatorModule,
                  CommonModule,
                  FormsModule,
                  MatNativeDateModule,
                  MatInputModule,
                  MatDatepickerModule,
              ],
              providers: [],
              declarations: [
                  ReportsComponent,
                  ReportsListComponent,
                  CreateReportComponent,
                  UploadReportModalComponent,
                  ReportsDetailComponent,
                  GeneralReportComponent,
              ],
              exports: [
              ],
              entryComponents: [ UploadReportModalComponent ]
          })
export class ReportsModule {
}
