import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocalStorage } from '../local.storage';
import { persistence } from '../rxjs/operators/persistence';


export abstract class AuthService<T, U> {

    protected storage: LocalStorage<T>;

    protected userSubject: BehaviorSubject<U> = new BehaviorSubject(undefined);

    get token(): T {

        return this.storage.value;
    }

    get user(): Observable<U> {

        return this.userSubject
                   .pipe(persistence(
                       // if no user, but token -> fetch user
                       (user) => !user && !!this.token,
                       () => this.getUser(this.token)
                   ));
    }

    protected abstract getUser(token: T): Observable<U>;

    protected abstract performAttempt(credentials: object): Observable<[ T, U ]>;

    protected abstract performRevoke(token: T): Observable<void>;

    protected createStorage(): void {

        this.storage = new LocalStorage<T>('app:auth');
    }

    protected remember(token: T, user?: U): Observable<void> {

        this.storage.set(token);
        this.userSubject.next(user || undefined);

        return of(null);
    }

    protected forget(): Observable<void> {

        this.storage.unset();

        this.userSubject.next(undefined);

        return of(null);
    }

    constructor() {

        this.createStorage();
    }

    check(): Observable<boolean> {

        return this.user
                   .pipe(map((u) => !!u));
    }

    guest(): Observable<boolean> {

        return this.check()
                   .pipe(map((isUser) => !isUser));
    }

    attempt(credentials: object): Observable<U> {

        return this.forget()
                   .pipe(
                       switchMap(() => this.performAttempt(credentials)),
                       switchMap(([ token, user ]) => this.remember(token, user)),
                       switchMap(() => this.user)
                   );
    }

    revoke(): Observable<void> {

        if (!this.token) {

            return this.forget();
        }

        return this.performRevoke(this.token)
                   .pipe(switchMap(() => this.forget()));
    }
}
