import {Component, Inject, OnInit} from '@angular/core';
import {UserModel} from '../../../models/user.model';
import {FormControl, FormGroup} from '@angular/forms';
import {Validators} from '../../../../common/forms/validators';
import {Validations} from '../../../forms/validations';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiResourcesService} from '../../../api/api-resources.service';
import {ApiService} from '../../../api/api.service';
import {ApiFormComponent} from '../../../forms/api-form.component';
import {AdminService} from '../../admin.service';

export interface AdminUserModifyDialogData {
  user: UserModel;
  title?: string;
}

@Component({
  selector: 'app-admin-user-modify',
  templateUrl: './admin-user-modify.component.html',
  styleUrls: ['./admin-user-modify.component.scss']
})
export class AdminUserModifyComponent extends ApiFormComponent {

  title: string;
  user: UserModel;

  protected createForm(): FormGroup {

    const adminFormGroup = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        ...Validations.getEmailRules()
      ])
    });

    return adminFormGroup;
  }

  constructor(
    protected dialogRef: MatDialogRef<AdminUserModifyComponent>,
    protected apiResourcesService: ApiResourcesService,
    apiService: ApiService,
    protected adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) data: AdminUserModifyDialogData,
  ) {

    super(apiService);
    this.title = 'Invite master user';
  }

  cancel(): void {

    this.dialogRef.close();
  }

  save(): void {

    this.withProgressBar(
      this.adminService.inviteAdminUser(this.form.value)
    ).subscribe((user: UserModel) => {
      this.dialogRef.close(user);
    });
  }

}
