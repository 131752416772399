import { ValidatorFn } from '@angular/forms';
import { Validators } from '../../common/forms/validators';

export class Validations {

    static getEmailRules(): ValidatorFn[] {

        return [
            Validators.email,
            Validators.maxLength(255)
        ];
    }

    static getPasswordRules(): ValidatorFn[] {

        return [
            Validators.minLength(6),
            Validators.maxLength(50)
        ];
    }
}
