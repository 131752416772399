import { Constructor, Dictionary } from '../types';
import { BaseFactory } from '../base.factory';
import { BaseModel } from './base.model';

export class ModelFactory<T extends BaseModel> extends BaseFactory<T> {

    constructor(protected model: Constructor<T>) {

        super((attributes?: Dictionary) => new this.model(attributes));
    }
}
