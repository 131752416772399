import {BaseModel} from '../../common/model/base.model';
import {Property} from '../../common/model/property.decorator';

export class CurrencyModel extends BaseModel {

  @Property()
  id: number;

  @Property()
  name: string;

  @Property({
    sourceName: 'short_name'
  })
  shortName: string;

  @Property()
  selected?: boolean;
}
