import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserRole } from '../models/user.model';
import { roleMatcher } from '../auth/role.matcher';
import { homeOrLogin } from '../auth/home-or-login';
import {UserGuard} from '../../common/auth/user.guard';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUsersInfoComponent} from './admin-users/admin-users-info/admin-users-info.component';

const routes: Routes = [
    {
        path: 'admin',
        component: AdminDashboardComponent,
        canActivate: [ UserGuard ],
        data: {
            authUserMatcher: roleMatcher(UserRole.SuperUser),
            authFallbackUrl: homeOrLogin
        },
        children: [
          {
            path: '',
            component: AdminUsersComponent
          },
          {
            path: ':id',
            component: AdminUsersInfoComponent,
          }
        ]

    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [ RouterModule ]
})
export class AdminRoutingModule {
}
