import * as UrlPattern from 'url-pattern';
import { Dictionary, Fabricator, Identifier, ValueOrGetter } from '../types';
import {
    PaginatedItems,
    RawHeaders,
    RawParams,
    RequestParams,
    ResourceAction,
    ResourceActionScope, ResourceQueryParamNames,
    ResponseType,
    RestClient
} from './types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isObject, value } from '../utils';

export class Resource<T> {

    protected actionScopes: { [ action: number ]: Array<ResourceActionScope<Resource<T>>> } = {};
    protected params: RawParams = {};
    protected headers: RawHeaders;
    protected responseType: ResponseType = ResponseType.Json;
    protected queryNames: ResourceQueryParamNames = {
        skip: 'skip',
        take: 'take',
        page: 'page',
        pageSize: 'pageSize',
        order: 'order'
    };

    protected get idName(): string {

        return 'id';
    }

    protected get pathPattern(): UrlPattern {

        return new UrlPattern(`/${this.name}(/:${this.idName})(:subPath)`);
    }

    protected get fabricator(): Fabricator<T> {

        return (v) => v;
    }

    protected get actionParams(): RequestParams {

        if (!Object.keys(this.params).length) {

            return {};
        }

        const pathParamNames = (this.pathPattern as any).names;

        return Object.keys(this.params)
                     .reduce((params, name) => {

                         const group = pathParamNames.includes(name) ? 'path' : 'query';

                         params[ group ] = params[ group ] || {};

                         params[ group ][ name ] = this.params[ name ];

                         return params;
                     }, {});
    }

    get hasParents(): boolean {

        return !!this.parents.length;
    }

    get path(): string {

        const { path } = this.actionParams;

        return this.buildPath(path);
    }

    get url(): string {

        const params = this.actionParams;

        return this.client.url(this.buildPath(params.path), params.query);
    }

    protected buildPath(params?: RawParams): string {

        if (this.hasParents) {

            return this.parents
                       .slice()
                       .reverse()
                       .reduce((path, resource) => {

                           return resource.where('subPath', path).path;
                       }, this.pathPattern.stringify(params));
        }

        return this.pathPattern.stringify(params);
    }

    protected applyActionScopes(action: ResourceAction): void {

        if (Array.isArray(this.actionScopes[ action ])) {

            this.actionScopes[ action ].forEach((scope) => scope(this));
            delete this.actionScopes[ action ];
        }
    }

    protected isPaginated(response: any): response is PaginatedItems<any> {

        return isObject(response) && [ 'data', 'links', 'meta' ].every((key) => response.hasOwnProperty(key));
    }

    protected paginate(response: PaginatedItems<any>): PaginatedItems<T> {

      return {
          data: response.data.map((item) => this.fabricator(item)),
          meta: {
            last_page: response.meta.last_page,
            current_page: response.meta.current_page,
            per_page: response.meta.per_page,
            total: response.meta.total,
          }
      };
    }

    constructor(protected client: RestClient, protected name: string, protected parents: Resource<T>[] = []) {
    }

    useHeader(header: string | RawHeaders, headerValue?: string | string[]): this {

        this.headers = this.headers || {};

        if (isObject(header)) {

            Object.keys(header)
                  .forEach((name) => this.useHeader(name, header[ name ]));
        } else {

            this.headers[ header ] = headerValue;
        }

        return this;
    }

    useAuthBearer(token: ValueOrGetter<string>): this {

        return this.useHeader('Authorization', `Bearer ${value(token)}`);
    }

    for(...parents: Identifier[]): this {

        this.parents.every((resource, i) => {

            if (parents[ i ]) {

                resource.whereId(parents[ i ]);

                return true;
            }

            return false;
        });

        return this;
    }

    when(action: ResourceAction | ResourceAction[], scope: ResourceActionScope<this>): this {

        if (Array.isArray(action)) {

            action.forEach((one) => this.when(one, scope));
        } else {

            this.actionScopes[ action ] = this.actionScopes[ action ] || [];
            this.actionScopes[ action ].push(scope);
        }

        return this;
    }

    where(param: string | RawParams, paramValue?: any): this {

        if (isObject(param)) {

            Object.keys(param)
                  .forEach((name) => this.where(name, param[ name ]));
        } else {

            this.params[ param ] = paramValue;
        }

        return this;
    }

    whereId(id: Identifier): this {

        return this.where(this.idName, id);
    }

    whereSub(path: string): this {

        return this.where('subPath', path);
    }

    skip(n: number): this {

        return this.where(this.queryNames.skip, n);
    }

    take(n: number): this {

        return this.where(this.queryNames.take, n);
    }

    page(n: number, size: number): this {

        return this.where(this.queryNames.page, n)
                   .where(this.queryNames.pageSize , size);
    }

    order(by: string, desc: boolean = false): this {

        return this.where(this.queryNames.order, `${desc ? '-' : ''}${by}`);
    }

    get(): Observable<Array<T> | PaginatedItems<T> | any> {

        this.applyActionScopes(ResourceAction.Get);

        const { path, query } = this.actionParams;

        return this.client
                   .get(this.buildPath(path), {
                       headers: this.headers,
                       params: query,
                       responseType: this.responseType
                   })
                   .pipe(map((response) => {

                       if (this.isPaginated(response)) {

                           return this.paginate(response);
                       }
                       // const data = response.data ? response.data : response;
                       // return (Array.isArray(data) ? data : [ data ]).map((item) => this.fabricator(item));
                       return (Array.isArray(response) ? response : [ response ]).map((item) => this.fabricator(item));
                   }));
    }

    first(): Observable<T> {

        return this.get()
                   .pipe(map((response: Array<T> | PaginatedItems<T>) => Array.isArray(response) ? response.shift()
                                                                                                 : response.data.shift()));
    }

    find(id: Identifier): Observable<T> {

        return this.whereId(id).first();
    }

    create(update: Dictionary): Observable<T> {

        this.applyActionScopes(ResourceAction.Create);

        const { path, query } = this.actionParams;

        return this.client
                   .post(this.buildPath(path), update, {
                       headers: this.headers,
                       params: query,
                       responseType: this.responseType
                   })
                   .pipe(map((response) => this.fabricator(response)));
    }

    update(update: Dictionary, id?: Identifier): Observable<T | boolean> {

        this.applyActionScopes(ResourceAction.Update);

        if (id) {

            this.whereId(id);
        }

        const { path, query } = this.actionParams;

        return this.client
                   .put(this.buildPath(path), update, {
                       headers: this.headers,
                       params: query,
                       responseType: this.responseType
                   })
                   .pipe(map((response) => {

                       if (response) {

                           return this.fabricator(response);
                       }
                   }));
    }

    delete(id?: Identifier): Observable<void> {

        this.applyActionScopes(ResourceAction.Delete);

        if (id) {

            this.whereId(id);
        }

        const { path, query } = this.actionParams;

        return this.client
                   .delete(this.buildPath(path), {
                       headers: this.headers,
                       params: query,
                       responseType: this.responseType
                   });
    }
}
