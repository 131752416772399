import { Constructor, Getter, PrimitiveConstructor, PrimitiveType, ValueOrGetter } from './types';

export function isPresent(x: any): boolean {

    return x !== undefined && x !== null;
}

export function isObject(x: any): x is object {

    return x !== null && typeof x === 'object';
}

export function isFunction(x: any): x is (...args: any[]) => any {

    return typeof x === 'function';
}

export function isGetter<T>(x: any): x is Getter<T> {

    return isFunction(x);
}

export function voider(): void {
}

export function value<T>(valueOrGetter: ValueOrGetter<T>): T {

    return isGetter(valueOrGetter) ? valueOrGetter() : valueOrGetter;
}

export function copy<T>(source: T): T {

    return JSON.parse(JSON.stringify(source));
}

export function isPrimitiveType(x: any): x is PrimitiveConstructor {

    return x === String || x === Number || x === Boolean;
}

export function isConstructor<T>(x: any): x is Constructor<T> {

    return isFunction(x);
}

export function valueToPrimitive(x: any, type: NumberConstructor): number;
export function valueToPrimitive(x: any, type: BooleanConstructor): boolean;
export function valueToPrimitive(x: any, type: StringConstructor): string;
export function valueToPrimitive(x: any, type: PrimitiveConstructor): PrimitiveType;
export function valueToPrimitive(x: any, type: PrimitiveConstructor): PrimitiveType {

    if (!isPresent(x)) {

        return x;
    }

    switch (type) {
        case Number:
            x = +x;
            break;
        case Boolean:
            x = !!x;
            break;
        case String:
            x = isPresent(x) ? `${x}` : '';
            break;
        default:
            x = undefined;
            break;
    }

    return x;
}

export function valueToType(x: any, type: NumberConstructor): number;
export function valueToType(x: any, type: BooleanConstructor): boolean;
export function valueToType(x: any, type: StringConstructor): string;
export function valueToType(x: any, type: PrimitiveConstructor): PrimitiveType;
export function valueToType<T>(x: any, type: Constructor<T>): T;
export function valueToType<T>(x: any, type: PrimitiveConstructor | Constructor<T>): PrimitiveType | T {

    if (!isPresent(x)) {

        return x;
    }

    if (isPrimitiveType(type)) {

        return valueToPrimitive(x, type);
    }

    return x instanceof type ? x : new (type as Constructor<T>)(x);
}

export function up<T>(root: T, getter: (root: T) => T | void): T {

    let child = getter(root);

    while (child) {

        root = child;

        child = getter(root);
    }

    return root;
}

export function upRecursive<T>(root: T, getter: (root: T) => T | void): T {

    const child = getter(root);

    return child ? upRecursive(child, getter) : root;
}

export function stringList(list: string | string[], separator: string = ' '): string[] {

    return Array.isArray(list) ? [ ...list ] : list.split(separator);
}
