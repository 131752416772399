import {Injectable} from '@angular/core';
import {ApiResourcesService} from '../api/api-resources.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  getCompany(id) {

    return this.apiResourcesService.company.find(id);
  }

  getCompanies() {

    return this.apiResourcesService.company.get();
  }

  createCompany(company) {

    return this.apiResourcesService.company.create(company);
  }

  editCompany(id, company) {

    return this.apiResourcesService.company.update(company, id);
  }

  deleteCompany(id) {

    return this.apiResourcesService.company.delete(id);
  }

  constructor(protected apiResourcesService: ApiResourcesService) {
  }
}
