import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';

export class AccountModel extends BaseModel {

    @Property()
    id: number;

    @Property()
    code: number;

    @Property()
    name: string;

    @Property()
    type: string;

    @Property()
    local_mapping: string;

    @Property()
    group_mapping: string;
}
