import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { AccountModel } from '../../models/account.model';
import { MatDialog } from '@angular/material';
import { APPLY_ACTION, SimpleActionModalComponent } from '../../shared/simple-action-modal/simple-action-modal.component';
import {AuthService} from '../../auth/auth.service';
import {Subscription} from 'rxjs';
import {ApiResourcesService} from '../../api/api-resources.service';
import * as moment from 'moment';
import {ReportType} from '../../models/report.model';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss']
})
export class AccountsListComponent implements OnInit, OnDestroy {
    protected subscriptions = new Subscription();

    @Input()
    accounts: AccountModel[];

    @Output()
    edit = new EventEmitter<AccountModel>();

    @Output()
    delete = new EventEmitter<AccountModel>();

    displayedColumns: string[] = [ 'code', 'name', 'type', 'localMapping', 'groupMapping', 'actions' ];
    disclaimer = '';
    isMaster: boolean;

    constructor(
      protected dialog: MatDialog,
      protected authService: AuthService,
      protected apiResourcesService: ApiResourcesService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.authService.user.subscribe((user) => {
            this.isMaster = user.isMaster;
            if (user && user.isMaster) {
                this.disclaimer = 'disclaimer';
            }
        }));
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    onEdit(account: AccountModel): void {

        this.edit.emit(account);
    }

    onDelete(account: AccountModel): void {

        const dialogRef = this.dialog.open(SimpleActionModalComponent, {
          width: '400px',
          data: { message: `Are you sure you want to delete account ${account.name}?` }
        });

        dialogRef.afterClosed().subscribe((action) => {
          if (action === APPLY_ACTION) {
            this.delete.emit(account);
          }
        });
    }

    downloadAcc() {
        const link = this.apiResourcesService.exportAccounts
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;
        window.open(link);
    }

}
