import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { Validators } from '../../common/forms/validators';
import { ApiResourcesService } from '../api/api-resources.service';
import { Validations } from '../forms/validations';
import { ApiFormComponent } from '../forms/api-form.component';

@Component({
               selector: 'app-join',
               templateUrl: './join.component.html',
               styleUrls: [ './join.component.scss' ]
           })
export class JoinComponent extends ApiFormComponent {

    protected createForm(): FormGroup {

        return new FormGroup({
                                 invite_key: new FormControl(this.route.snapshot.paramMap.get('token'), [
                                     Validators.required
                                 ]),
                                 first_name: new FormControl(null, [
                                     Validators.required,
                                     Validators.maxLength(50)
                                 ]),
                                 last_name: new FormControl(null, [
                                     Validators.required,
                                     Validators.maxLength(50)
                                 ]),
                                 ...Validators.withConfirmation(
                                     new FormControl(null, [
                                         Validators.required,
                                         ...Validations.getPasswordRules()
                                     ]), 'password', this.subscriptions
                                 )
                             });
    }

    constructor(
        protected router: Router, protected route: ActivatedRoute, protected apiResourcesService: ApiResourcesService,
        apiService: ApiService
    ) {

        super(apiService);
    }

    join(): void {

        this.withProgressBar(this.apiResourcesService.auth.user.create(this.form.value))
            .subscribe(() => this.router.navigate([ '/login' ]));
    }
}
