<app-dashboard-layout>
  <div class="toolbar">
    <button mat-button [matMenuTriggerFor]="menu">
      {{ user.fullName }}
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="profile()">
        <mat-icon>edit</mat-icon>
        <span>Profile</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
  <div class="sidenav">
    <mat-nav-list>
      <ng-template [ngIf]="user.isAdmin">
        <a mat-list-item routerLinkActive="active" routerLink="/users">
          <mat-icon matListIcon>group</mat-icon>
          <span matLine>Users</span>
        </a>
      <!--  <a mat-list-item routerLinkActive="active" routerLink="/companies">
          <mat-icon matListIcon>category</mat-icon>
          <span matLine>Companies</span>
        </a>
        <a mat-list-item  routerLink="/accounts">
          <mat-icon matListIcon>payment</mat-icon>
          <span matLine>Accounts</span>
        </a>-->
      </ng-template>
     <!-- <a mat-list-item routerLinkActive="active" routerLink="/reports">
        <mat-icon matListIcon>assignment</mat-icon>
        <span matLine>Reports</span>
      </a>-->
    </mat-nav-list>
  </div>
</app-dashboard-layout>
