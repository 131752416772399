<div class="container">
    <mat-toolbar>
        <button mat-mini-fab color="primary" title="Refresh" (click)="reload()" [disabled]="progressBarMode">
            <mat-icon>refresh</mat-icon>
        </button>
        <span fxFlex="1 1 auto"></span>
        <button mat-raised-button color="primary" (click)="add()" title="Add">
            <mat-icon>add</mat-icon>
        </button>
    </mat-toolbar>
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
    <app-users-list
      [users]="users"
      (delete)="onDelete($event)"
    ></app-users-list>
    <mat-paginator
      *ngIf="users && users.length && paginationVisibility"
      [pageSize]="perPage"
      [showFirstLastButtons]="true"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)"
    ></mat-paginator>
</div>
