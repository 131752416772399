import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '../../models/user.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { Validators } from '../../../common/forms/validators';
import { ApiFormComponent } from '../../forms/api-form.component';
import { Validations } from '../../forms/validations';
import { ApiResourcesService } from '../../api/api-resources.service';
import {UserService} from '../user.service';

export interface UserModifyDialogData {
    user: UserModel;
    title?: string;
    isMaster?: boolean;
}

@Component({
     selector: 'app-user-modify',
     templateUrl: './user-modify.component.html',
     styleUrls: [ './user-modify.component.scss' ]
 })
export class UserModifyComponent extends ApiFormComponent {
    title: string;
    user: UserModel;
    isMaster: boolean;

  get userId(): number {

        return this.user && this.user.id;
    }

    get isNew(): boolean {

        return !this.userId;
    }

    protected createForm(): FormGroup {

        const formGroup = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                ...Validations.getEmailRules()
            ]),
            first_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            last_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            ...Validators.withConfirmation(
                new FormControl(null, [
                    ...Validations.getPasswordRules()
                ]), 'password', this.subscriptions
            )
        });

        if (!this.isNew) {

            formGroup.addControl(
                ...Validators.passworded(formGroup.get('password'), 'password', this.subscriptions, Validations.getPasswordRules()));
        }

        if (this.isNew &&  this.isMaster) {
            formGroup.addControl('master_checkbox', new FormControl(false, []));
        }

        return formGroup;
    }

    protected updateFormValue(): void {

        if (!this.isNew) {

            this.form.patchValue(this.user.toObject());
        }
    }

    constructor(
        protected dialogRef: MatDialogRef<UserModifyComponent>,
        protected apiResourcesService: ApiResourcesService,
        protected userService: UserService,
        apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) data: UserModifyDialogData,
    ) {

        super(apiService);

        this.isMaster = data && data.isMaster;
        this.user = data && data.user;
        this.title = data && data.title || (this.isNew ? 'Create user' : 'Edit user');
    }

    cancel(): void {

        this.dialogRef.close();
    }

    save(): void {
      if (this.form.get('master_checkbox') && this.form.get('master_checkbox').value) {
          this.withProgressBar(
            this.userService.createMasterUser(this.form.value))
            .subscribe((user: UserModel) => {
                this.dialogRef.close(user);
            });
      } else {
          this.withProgressBar(
              this.apiResourcesService.staff[ this.isNew ? 'create' : 'update' ](this.form.value, this.userId))
              .subscribe((user: UserModel) => {
                  this.dialogRef.close(user);
              });
      }
    }
}
