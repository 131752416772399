import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyModel } from '../../models/company.model';
import {MatDialog, MatTreeNestedDataSource} from '@angular/material';
import {NestedTreeControl} from '@angular/cdk/tree';

interface TreeNode {
  children?: TreeNode[];
}

@Component({
   selector: 'app-companies-list',
   templateUrl: './companies-list.component.html',
   styleUrls: [ './companies-list.component.scss' ]
})
export class CompaniesListComponent {

  roots: CompanyModel[] = [];

  @Input()
  set companies(companies: CompanyModel[]) {
      this.roots = this.makeTrees(companies.map((company) => company.clone()));
      this.initTree(this.roots);
  }

  @Output()
  add = new EventEmitter<CompanyModel>();
  @Output()
  edit = new EventEmitter<CompanyModel>();
  @Output()
  delete = new EventEmitter<CompanyModel>();

  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();

  constructor(
    protected dialog: MatDialog
  ) {}


  initTree(data): void {
    this.dataSource.data = data;
  }

  protected makeTrees(companies: CompanyModel[]): CompanyModel[] {
        const notRoots: number[] = [];
        companies.forEach((company) => {
            if (company.parentId) {
                const parent = companies.find((other) => other.id === company.parentId);
                if (parent) {
                    if (!Array.isArray(parent.children)) {
                        parent.children = [];
                    }
                    parent.children.push(company);
                    notRoots.push(company.id);
                }
            }
        });
        return companies.filter((company) => notRoots.indexOf(company.id) === -1);
    }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  onAdd(parent: CompanyModel): void {
    this.add.emit(parent);
  }

  onEdit(company: CompanyModel): void {
      this.edit.emit(company);
  }

  onDelete(company: CompanyModel): void {
    this.delete.emit(company);
  }
}
