import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UserModel } from '../models/user.model';
import { ApiResourcesService } from '../api/api-resources.service';
import { finalize } from 'rxjs/operators';
import { ReportModel } from '../models/report.model';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import {MatDialog, MatPaginator, MatTableDataSource, PageEvent} from '@angular/material';
import {PaginatedItems} from '../../common/http/types';
import {CompanyModel} from '../models/company.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {Moment} from "moment";

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM',
    },
    display: {
        dateInput: 'YYYY-MM',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
               selector: 'app-reports',
               templateUrl: './reports.component.html',
               styleUrls: [ './reports.component.scss' ],
                providers: [
                    {
                        provide: DateAdapter,
                        useClass: MomentDateAdapter,
                        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
                    },
                    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
                ]
           })
export class ReportsComponent implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();

    // @todo ngIf-like directive
    user: UserModel;

    progressBarMode: string;
    reports: ReportModel[];
    resultReports: ReportModel[];
    companies: CompanyModel[];

    notCompanyManager = true;

    perPage = 20;
    pageIndex = 0;
    totalSize;
    dataSource: any;
    paginationVisibility = true;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    companyId: number;
    form: FormGroup;

    date = new FormControl({value: moment(), disabled: true});
    formDate = new FormControl({value: '', disabled: true});
    tomorrow = new Date();

    protected createForm() {
        this.form = this.fb.group({
            companies: new FormControl(null, [])
        });
    }

    constructor(
        protected apiResourcesService: ApiResourcesService,
        protected dialog: MatDialog,
        protected authService: AuthService,
        private router: Router,
        protected fb: FormBuilder
    ) {
        this.tomorrow.setDate(this.tomorrow.getDate());
    }

    ngOnInit(): void {
        this.createForm();

        this.subscriptions.add(this.authService.user.subscribe((user) => this.user = user));

        if (this.user.isStaff) {
            this.getUserCompanies();
        } else {
          this.notCompanyManager = false;
        }


        this.reload();
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    getUserCompanies() {
        this.subscriptions.add(
            this.apiResourcesService.userCompanies
                .get()
                .subscribe((companies) => {
                    if (companies.length > 0) {
                        this.form.controls.companies.setValue(companies[0].id);
                    }
                    this.companyId = companies[0].id;
                    this.companies = companies;
                    this.notCompanyManager = false;
                },
                    err => {
                        this.notCompanyManager = true;
                    })
        );
    }

    changeActiveCompany($event) {
        this.companyId = $event.value;
    }

    createReport() {
        this.router.navigate(['reports/create-report', this.companyId]);
    }

    handlePage(e: PageEvent): void {
      this.pageIndex = e.pageIndex;
      this.perPage = e.pageSize;
      this.reload();
    }

    reload(): void {

        this.progressBarMode = 'query';

        this.apiResourcesService.report
          .page(this.pageIndex + 1, this.perPage)
          .get()
          .pipe(finalize(() => this.progressBarMode = null))
          .subscribe((reports: PaginatedItems<any>) => {
            this.reports = reports.data;
            this.resultReports = reports.data;
            this.dataSource = new MatTableDataSource<any>(this.reports);
            this.dataSource.paginator = this.paginator;
            this.perPage = reports.meta.per_page;
            this.totalSize = reports.meta.total;
            this.pageIndex = reports.meta.current_page - 1;

            this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
          });

        this.formDate.setValue('');
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    }
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
        this.closePicker();
        datepicker.close();
    }

    closePicker() {
        this.resultReports = this.reports.filter(obj => {
            return obj.date === this.date.value.format('YYYY-MM');
        });
    }
}
