import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ApiResourcesService} from '../api/api-resources.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
    progressBarMode: string;
    form: FormGroup;
    currencyArray: FormArray;
    currencyList: any;

    get currencyArrayControl(): FormArray {
        return this.form.get('currencyArray') as FormArray;
    }

    constructor(
        private fb: FormBuilder,
        protected apiResourcesService: ApiResourcesService
    ) { }

    ngOnInit() {
        this.createForm();
        this.getCurrencyList();
    }

    createForm() {
        this.form = this.fb.group({
            currencyArray: this.fb.array([])
        });
    }

    createItem(id: number, name: string, shortName: string,  active: boolean): FormGroup {
        return this.fb.group({
            id: this.fb.control(id, []),
            active: this.fb.control(active, [])
        });
    }

    getCurrencyList() {
        this.progressBarMode = 'query';
        this.apiResourcesService.currency
            .get()
            .pipe(finalize(() => {
                this.progressBarMode = null;
            }))
            .subscribe((currency) => {
                this.currencyList = currency;
                this.currencyArray = this.form.get(`currencyArray`) as FormArray;
                for (let currency of  this.currencyList) {
                    this.currencyArray.push(this.createItem(currency.id, currency.name, currency.shortName, currency.selected));
                }
            });
    }

    save(): void {
        this.progressBarMode = 'query';
        const selectedCurrency = [];

        for (let i = 0; i < this.currencyArrayControl.length; i++) {
            if (this.currencyArrayControl.value[i].active === true) {
                selectedCurrency.push(this.currencyArrayControl.value[i].id);
            }
        }

        this.apiResourcesService.setActiveCurrency
            .create({
                currencies_ids: selectedCurrency
            })
            .pipe(finalize(() => {
                this.progressBarMode = null;
            })).subscribe();
    }

}
