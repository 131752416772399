/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blank-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/router";
import * as i5 from "./blank-layout.component";
var styles_BlankLayoutComponent = [i0.styles];
var RenderType_BlankLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlankLayoutComponent, data: {} });
export { RenderType_BlankLayoutComponent as RenderType_BlankLayoutComponent };
export function View_BlankLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "content"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); }, null); }
export function View_BlankLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blank-layout", [], null, null, null, View_BlankLayoutComponent_0, RenderType_BlankLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i5.BlankLayoutComponent, [], null, null)], null, null); }
var BlankLayoutComponentNgFactory = i1.ɵccf("app-blank-layout", i5.BlankLayoutComponent, View_BlankLayoutComponent_Host_0, {}, {}, []);
export { BlankLayoutComponentNgFactory as BlankLayoutComponentNgFactory };
