import { TemplatesConfig } from '../../common/forms/validation-renderer';

export const ValidationTemplates: TemplatesConfig = {
    templates: {
        required: 'The {{subject}} field is required.',
        minlength: 'The {{subject}} must be at least {{requiredLength}} characters.',
        maxlength: 'The {{subject}} may not be greater than {{requiredLength}} characters.',
        equalTo: 'The {{subject}} should be equal to {{target}}.',
        email: 'Invalid email address'
    },
    subjectTemplates: {}
};
