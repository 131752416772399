import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLayoutComponent } from './blank-layout.component';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import {CurrencyComponent} from '../currency/currency.component';
import {UiRoutingModule} from './ui-routing.module';
import {AdjustmentComponent} from '../adjustment/adjustment.component';
import {OnlyDigitsDirective} from '../../common/forms/digits.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthModule} from '../auth/auth.module';
import {ApiModule} from '../api/api.module';
import {
    MatAutocompleteModule,
    MatCardModule, MatCheckboxModule, MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule, MatInputModule,
    MatListModule, MatPaginatorModule, MatProgressBarModule,
    MatSelectModule, MatSortModule, MatTableDataSource,
    MatTableModule
} from '@angular/material';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";


@NgModule({
              declarations: [
                  BlankLayoutComponent,
                  DashboardLayoutComponent,
                  PageNotFoundComponent,
                  CurrencyComponent,
                  AdjustmentComponent,
                  OnlyDigitsDirective
              ],
              imports: [
                  CommonModule,
                  RouterModule,
                  FlexLayoutModule,
                  MatToolbarModule,
                  MatIconModule,
                  MatButtonModule,
                  MatSidenavModule,
                  UiRoutingModule,
                  CommonModule,
                  ReactiveFormsModule,
                  AuthModule,
                  ApiModule,
                  MatDividerModule,
                  MatDialogModule,
                  MatListModule,
                  MatSelectModule,
                  MatTableModule,
                  MatGridListModule,
                  MatCardModule,
                  MatProgressBarModule,
                  MatInputModule,
                  MatPaginatorModule,
                  MatSortModule,
                  FormsModule,
                  MatCheckboxModule,
                  MatNativeDateModule,
                  MatDatepickerModule
              ],
              exports: [
                  FlexLayoutModule,
                  MatToolbarModule,
                  MatIconModule,
                  MatButtonModule,
                  MatSidenavModule,
                  BlankLayoutComponent,
                  DashboardLayoutComponent,
                  PageNotFoundComponent,
                  CurrencyComponent,
                  MatTableModule,
                  OnlyDigitsDirective,
                  MatNativeDateModule,
                  MatDatepickerModule
              ]
          })
export class UiModule {
}
