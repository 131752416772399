import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
export class AccountModel extends BaseModel {
}
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "id", void 0);
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "code", void 0);
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "name", void 0);
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "type", void 0);
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "local_mapping", void 0);
tslib_1.__decorate([
    Property()
], AccountModel.prototype, "group_mapping", void 0);
