<mat-toolbar *ngIf="users && !users.length" color="warn" fxLayoutAlign="space-around center">No users yet</mat-toolbar>
<table *ngIf="users && users.length" mat-table [dataSource]="users" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#ID</th>
        <td mat-cell *matCellDef="let user">{{user.id}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let user">{{user.email}}</td>
    </ng-container>

    <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let user">{{user.fullName}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
            <!--<button type="button" mat-icon-button (click)="onEdit(user)" color="accent">
                <mat-icon>edit</mat-icon>
            </button>-->
            <button type="button" mat-icon-button (click)="onDelete(user)" color="warn">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
