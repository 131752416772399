import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
               selector: 'app-blank-layout',
               templateUrl: './blank-layout.component.html',
               styleUrls: [ './blank-layout.component.scss' ]
           })
export class BlankLayoutComponent {

    appName = environment.appName;
}
