import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { CompanyModel } from './company.model';
import { ReportAccountModel } from './report.account.model';
export var ReportType;
(function (ReportType) {
    ReportType["Json"] = "json";
    ReportType["Xlsx"] = "xlsx";
    ReportType["XlsxLocalCurrency"] = "xlsx-local-currency";
    ReportType["XlsxConsolidated"] = "xlsx-consolidated";
    ReportType["XlsxAdjustment"] = "xlsx-adjustment";
})(ReportType || (ReportType = {}));
export class ReportModel extends BaseModel {
}
tslib_1.__decorate([
    Property()
], ReportModel.prototype, "date", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'company_id'
    })
], ReportModel.prototype, "companyId", void 0);
tslib_1.__decorate([
    Property()
], ReportModel.prototype, "currency", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'target_currency'
    })
], ReportModel.prototype, "targetCurrency", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'end_rate'
    })
], ReportModel.prototype, "endRate", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'middle_rate'
    })
], ReportModel.prototype, "midRate", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: CompanyModel
    })
], ReportModel.prototype, "company", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: ReportAccountModel,
        isArray: true
    })
], ReportModel.prototype, "accounts", void 0);
tslib_1.__decorate([
    Property()
], ReportModel.prototype, "ctr", void 0);
export class TotalModel extends BaseModel {
}
tslib_1.__decorate([
    Property({
        sourceName: 'account_id'
    })
], TotalModel.prototype, "id", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'total_balance'
    })
], TotalModel.prototype, "balance", void 0);
export class TotalReportModel extends BaseModel {
}
tslib_1.__decorate([
    Property({
        typeOrFactory: ReportModel,
        isArray: true
    })
], TotalReportModel.prototype, "reports", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: TotalModel,
        isArray: true
    })
], TotalReportModel.prototype, "total", void 0);
export class ReportChangeModel extends BaseModel {
}
tslib_1.__decorate([
    Property()
], ReportChangeModel.prototype, "account_id", void 0);
tslib_1.__decorate([
    Property()
], ReportChangeModel.prototype, "balance", void 0);
