import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { UserModel } from './user.model';
import { CurrencyModel } from './currency.model';
export class CompanyModel extends BaseModel {
    get isMasterCompany() {
        return this.managers[0].isMaster;
    }
}
tslib_1.__decorate([
    Property()
], CompanyModel.prototype, "id", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'organization_id'
    })
], CompanyModel.prototype, "organizationId", void 0);
tslib_1.__decorate([
    Property()
], CompanyModel.prototype, "name", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'parent_id'
    })
], CompanyModel.prototype, "parentId", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: UserModel,
        isArray: true
    })
], CompanyModel.prototype, "managers", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: CurrencyModel
    })
], CompanyModel.prototype, "currency", void 0);
