<div class="container">
    <mat-toolbar>
        <button mat-mini-fab color="primary" title="Refresh" (click)="reload()" [disabled]="progressBarMode">
            <mat-icon>refresh</mat-icon>
        </button>
       <!-- <ng-template [ngIf]="user.isMaster">
          <span fxFlex="1 1 auto"></span>
          <a [routerLink]="['/reports/general-report']" *ngIf="reports && reports.length">
            <button
              class="btn"
              mat-raised-button
              color="primary"
            >
              <mat-icon>list</mat-icon>
            </button>
          </a>
        </ng-template>-->
        <span fxFlex="1 1 auto"></span>
        <div class="date_wrapper">
            <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input
                    matInput
                    [matDatepicker]="dp"
                    [formControl]="formDate"
                    [max]="tomorrow"
                >
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker
                    #dp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, dp)"
                    (closed)="closePicker()"
                    panelClass="month-picker"
                    disabled="false"
                    color="primary"
                >
                </mat-datepicker>
            </mat-form-field>
        </div>
        <ng-template [ngIf]="user.isStaff">
            <div class="companies_select" [formGroup]="form" *ngIf="companies && companies.length > 1">
                <mat-select
                    placeholder="Company"
                    formControlName="companies"
                    (selectionChange)="changeActiveCompany($event)"
                >
                    <mat-option
                        *ngFor="let company of companies"
                        [value]="company.id"
                    >
                        {{company.name}}
                    </mat-option>
                </mat-select>
            </div>

            <button
              mat-raised-button
              color="primary"
              (click)="createReport()"
              [disabled]="notCompanyManager"
            >
              Create / Update report
            </button>
        </ng-template>
    </mat-toolbar>
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
    <app-reports-list
        [reports]="resultReports"
        [companyId]="companyId"
        [user]="user"
    ></app-reports-list>
    <mat-paginator
      *ngIf="reports && reports.length && paginationVisibility"
      [pageSize]="perPage"
      [showFirstLastButtons]="true"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)"
    ></mat-paginator>
</div>
