import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './ui/page-not-found.component';
import { BlankLayoutComponent } from './ui/blank-layout.component';

const routes: Routes = [
    {
        path: '**',
        component: BlankLayoutComponent,
        children: [
            {
                path: '',
                component: PageNotFoundComponent
            }
        ]
    }
];


@NgModule({
              imports: [ RouterModule.forRoot(routes, { enableTracing: !environment.production }) ],
              exports: [ RouterModule ]
          })
export class AppRoutingModule {
}
