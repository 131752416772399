import {
    Directive,
    EmbeddedViewRef,
    Input,
    KeyValueChangeRecord,
    KeyValueChanges,
    KeyValueDiffer,
    KeyValueDiffers,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ValidationRenderer } from './validation-renderer';
import { isPresent } from '../utils';

export class ErrorsOfContext {

    constructor(public control: string, public validator: string, public $implicit: string, public context: any) {
    }
}

@Directive({ selector: '[errors]' })
export class ErrorsDirective implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();
    protected differ: KeyValueDiffer<string, any>;
    protected control: AbstractControl;
    protected changesViews: { [ key: string ]: EmbeddedViewRef<ErrorsOfContext> } = {};

    @Input()
    errorsSubject: string;

    @Input()
    set errorsOf(control: AbstractControl) {

        this.control = control;
    }

    protected getChangeView(record: KeyValueChangeRecord<string, any>): EmbeddedViewRef<ErrorsOfContext> {

        if (isPresent(this.changesViews[ record.key ])) {

            return this.changesViews[ record.key ];
        }
    }

    protected removeChangeView(record: KeyValueChangeRecord<string, any>): void {

        if (isPresent(this.changesViews[ record.key ])) {

            this.viewContainer.remove(this.viewContainer.indexOf(this.changesViews[ record.key ]));
        }
    }

    protected createChangeView(record: KeyValueChangeRecord<string, any>): void {

        this.changesViews[ record.key ] = this.viewContainer
                                              .createEmbeddedView(this.template,
                                                                  new ErrorsOfContext(
                                                                      this.errorsSubject,
                                                                      record.key,
                                                                      this.validationRenderer
                                                                          .render(this.errorsSubject, record.key, record.currentValue),
                                                                      record.currentValue
                                                                  ));
    }

    protected updateChangeView(record: KeyValueChangeRecord<string, any>): void {

        const view = this.getChangeView(record);
        view.context.$implicit = this.validationRenderer.render(this.errorsSubject, record.key, record.currentValue);
        view.context.context = record.currentValue;
    }

    protected applyChanges(changes: KeyValueChanges<string, any>): void {

        changes.forEachAddedItem((record) => this.createChangeView(record));
        changes.forEachChangedItem((record) => this.updateChangeView(record));
        changes.forEachRemovedItem((record) => this.removeChangeView(record));
    }

    protected doCheck(errors: ValidationErrors) {

        if (!this.differ) {

            this.differ = this.differs.find(errors).create();
        }

        const changes = this.differ.diff(errors);

        if (changes) {

            this.applyChanges(changes);
        }
    }

    constructor(
        protected viewContainer: ViewContainerRef, protected template: TemplateRef<ErrorsOfContext>, protected differs: KeyValueDiffers,
        protected validationRenderer: ValidationRenderer
    ) {
    }

    ngOnInit() {

        this.subscriptions.add(this.control
                                   .statusChanges
                                   .pipe(
                                       startWith(null),
                                       map(() => this.control.errors || {})
                                   )
                                   .subscribe((errors) => this.doCheck(errors)));
    }

    ngOnDestroy() {

        this.subscriptions.unsubscribe();
    }
}
