import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { ReportsComponent } from './reports.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { homeOrLogin } from '../auth/home-or-login';
import { UserRole } from '../models/user.model';
import { roleMatcher } from '../auth/role.matcher';
import {ReportsDetailComponent} from './reports-detail/reports-detail.component';
import {GeneralReportComponent} from './general-report/general-report.component';

const routes: Routes = [
    {
        path: 'reports',
        component: DashboardComponent,
        canActivate: [ UserGuard ],
        data: {
            authUserMatcher: roleMatcher([ UserRole.MasterUser, UserRole.User ]),
            authFallbackUrl: homeOrLogin
        },
        children: [
            {
                path: '',
                component: ReportsComponent,
            },
            {
                path: 'create-report/:companyId',
                component: CreateReportComponent,
            },
            {
              path: 'general-report',
              component: GeneralReportComponent,
            },
            {
              path: 'details/:companyId/:date',
              component: ReportsDetailComponent,
            }
        ]
    }
];

@NgModule({
              imports: [
                  RouterModule.forChild(routes)
              ],
              exports: [ RouterModule ]
          })
export class ReportsRoutingModule {
}
