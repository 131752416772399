/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./companies-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/tree";
import * as i3 from "@angular/material/tree";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../company-item/company-item.component.ngfactory";
import * as i9 from "../company-item/company-item.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "../../../../node_modules/@angular/material/tree/typings/index.ngfactory";
import * as i14 from "./companies-list.component";
var styles_CompaniesListComponent = [i0.styles];
var RenderType_CompaniesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompaniesListComponent, data: {} });
export { RenderType_CompaniesListComponent as RenderType_CompaniesListComponent };
function View_CompaniesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-tree-node", [["class", "mat-tree-node"], ["matTreeNodeToggle", ""]], [[1, "aria-expanded", 0], [1, "aria-level", 0], [1, "role", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CdkTreeNodeToggle, null, [i3.MatTreeNodeToggle]), i1.ɵdid(2, 16384, null, 0, i3.MatTreeNode, [i1.ElementRef, i2.CdkTree, [8, null]], null, null), i1.ɵprd(2048, null, i2.CdkTreeNode, null, [i3.MatTreeNode]), i1.ɵdid(4, 16384, null, 0, i3.MatTreeNodeToggle, [i2.CdkTree, i2.CdkTreeNode], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["disabled", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-company-item", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.onAdd($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.onEdit($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.onDelete($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_CompanyItemComponent_0, i8.RenderType_CompanyItemComponent)), i1.ɵdid(8, 114688, null, 0, i9.CompanyItemComponent, [i10.MatDialog], { node: [0, "node"] }, { add: "add", edit: "edit", delete: "delete" })], function (_ck, _v) { var currVal_5 = ""; _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.context.$implicit; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isExpanded; var currVal_1 = ((i1.ɵnov(_v, 2).role === "treeitem") ? i1.ɵnov(_v, 2).level : null); var currVal_2 = i1.ɵnov(_v, 2).role; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); }); }
function View_CompaniesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "mat-nested-tree-node", [["class", "mat-nested-tree-node"]], [[1, "aria-expanded", 0], [1, "role", 0]], null, null, null, null)), i1.ɵprd(6144, null, i2.CdkNestedTreeNode, null, [i3.MatNestedTreeNode]), i1.ɵdid(2, 1196032, null, 1, i3.MatNestedTreeNode, [i1.ElementRef, i2.CdkTree, i1.IterableDiffers, [8, null]], null, null), i1.ɵqud(603979776, 2, { nodeOutlet: 1 }), i1.ɵprd(2048, null, i2.CdkTreeNode, null, [i3.MatNestedTreeNode]), i1.ɵprd(2048, null, i2.CDK_TREE_NODE_OUTLET_NODE, null, [i3.MatNestedTreeNode]), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "mat-tree-node"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "button", [["mat-icon-button", ""], ["matTreeNodeToggle", ""]], [[1, "aria-label", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵprd(6144, null, i2.CdkTreeNodeToggle, null, [i3.MatTreeNodeToggle]), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 16384, null, 0, i3.MatTreeNodeToggle, [i2.CdkTree, i2.CdkTreeNode], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "mat-icon-rtl-mirror mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-company-item", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.onAdd($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.onEdit($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.onDelete($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_CompanyItemComponent_0, i8.RenderType_CompanyItemComponent)), i1.ɵdid(15, 114688, null, 0, i9.CompanyItemComponent, [i10.MatDialog], { node: [0, "node"] }, { add: "add", edit: "edit", delete: "delete" }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ul", [], [[2, "company-tree-invisible", null]], null, null, null, null)), (_l()(), i1.ɵeld(17, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(18, 16384, [[2, 4]], 0, i3.MatTreeNodeOutlet, [i1.ViewContainerRef, [2, i2.CDK_TREE_NODE_OUTLET_NODE]], null, null)], function (_ck, _v) { _ck(_v, 12, 0); var currVal_8 = _v.context.$implicit; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).isExpanded; var currVal_1 = i1.ɵnov(_v, 2).role; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ("toggle " + _v.context.$implicit.name); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 12).inline; var currVal_6 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_7 = (_co.treeControl.isExpanded(_v.context.$implicit) ? "expand_more" : "chevron_right"); _ck(_v, 13, 0, currVal_7); var currVal_9 = !_co.treeControl.isExpanded(_v.context.$implicit); _ck(_v, 16, 0, currVal_9); }); }
export function View_CompaniesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-tree", [["class", "company-tree mat-tree"], ["role", "tree"]], null, null, null, i13.View_MatTree_0, i13.RenderType_MatTree)), i1.ɵprd(6144, null, i2.CdkTree, null, [i3.MatTree]), i1.ɵdid(2, 2342912, null, 1, i3.MatTree, [i1.IterableDiffers, i1.ChangeDetectorRef], { dataSource: [0, "dataSource"], treeControl: [1, "treeControl"] }, null), i1.ɵqud(603979776, 1, { _nodeDefs: 1 }), (_l()(), i1.ɵand(0, null, null, 2, null, View_CompaniesListComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.MatTreeNodeDef, [i1.TemplateRef], null, null), i1.ɵprd(2048, [[1, 4]], i2.CdkTreeNodeDef, null, [i3.MatTreeNodeDef]), (_l()(), i1.ɵand(0, null, null, 2, null, View_CompaniesListComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.MatTreeNodeDef, [i1.TemplateRef], { when: [0, "when"] }, null), i1.ɵprd(2048, [[1, 4]], i2.CdkTreeNodeDef, null, [i3.MatTreeNodeDef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.treeControl; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.hasChild; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_CompaniesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-companies-list", [], null, null, null, View_CompaniesListComponent_0, RenderType_CompaniesListComponent)), i1.ɵdid(1, 49152, null, 0, i14.CompaniesListComponent, [i10.MatDialog], null, null)], null, null); }
var CompaniesListComponentNgFactory = i1.ɵccf("app-companies-list", i14.CompaniesListComponent, View_CompaniesListComponent_Host_0, { companies: "companies" }, { add: "add", edit: "edit", delete: "delete" }, []);
export { CompaniesListComponentNgFactory as CompaniesListComponentNgFactory };
