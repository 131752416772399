<mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="center center">Login</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="attempt()" fxLayout="column">
            <mat-form-field>
                <input matInput placeholder="Email" type="email" formControlName="email" required autocomplete="current-email">
                <mat-error *errors="let message of form.get('email'); subject: 'email'">{{ message }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Password" type="password" formControlName="password" required autocomplete="current-password">
                <mat-error *errors="let message of form.get('password'); subject: 'password'">{{ message }}</mat-error>
            </mat-form-field>

            <mat-error *ngIf="message">{{ message }}</mat-error>

            <div class="mat-input-wrapper mat-form-field-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <button *ngIf="!progress" type="submit" mat-raised-button color="primary" [disabled]="form.invalid">Login</button>
                <mat-progress-bar *ngIf="progress" [mode]="progress"></mat-progress-bar>
            </div>
        </form>
    </mat-card-content>
</mat-card>
