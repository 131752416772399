import * as i from 'inflect';
import { Constructor, Dictionary, Fabricator, Identifier } from '../types';
import { copy, isFunction } from '../utils';
import { propertyFactoryGetter } from './property.decorator';

export type Mutator = 'get' | 'set';

export class BaseModel {

    static get idName(): string {

        return 'id';
    }

    protected attributes: Dictionary = {};

    get idName(): string {

        return (this.constructor as typeof BaseModel).idName;
    }

    get idValue(): Identifier {

        return this.getAttribute(this.idName);
    }

    get exists(): boolean {

        return !!this.idValue;
    }

    protected makeMutatorName(type: Mutator, name: string): string {

        return `${type}${i.camelize(i.underscore(name))}Attribute`;
    }

    protected getMutatorIfExists(type: Mutator, name: string): string {

        const mutator = this.makeMutatorName(type, name);

        return typeof this[ mutator ] === 'function' ? mutator : null;
    }

    protected getTypeFactoryIfExists(name: string): Fabricator<any> {

        const factory = this[ propertyFactoryGetter(name) ];

        if (isFunction(factory)) {

            return factory;
        }
    }

    protected getRawAttribute(name: string): any {

        if (this.attributes.hasOwnProperty(name)) {

            return this.attributes[ name ];
        }
    }

    protected getAttribute(name: string): any {

        const mutator = this.getMutatorIfExists('get', name);

        let value = this.getRawAttribute(name);

        if (mutator) {

            value = this[ mutator ](value);
        }

        return value;
    }

    protected setAttribute(name: string, value: any): this {

        const mutator = this.getMutatorIfExists('set', name);
        const typeFactory = this.getTypeFactoryIfExists(name);

        if (typeFactory) {

            value = typeFactory(value);
        }

        if (mutator) {

            value = this[ mutator ](value);
        }

        this.attributes[ name ] = value;

        return this;
    }

    protected get(name: string): any {

        return this.getAttribute(name);
    }

    protected set(name: string, value: any): this {

        return this.setAttribute(name, value);
    }

    constructor(attributes?: Dictionary) {

        if (attributes) {

            this.fill(attributes);
        }
    }

    fill(attributes: Dictionary): this {

        Object.keys(attributes)
              .forEach((name) => this.setAttribute(name, attributes[ name ]));

        return this;
    }

    fillFrom(other: this, only?: string[]): this {

        return this.fill(other.toObject(only));
    }

    equal(other: this): boolean {

        return other instanceof this.constructor && other.idValue === this.idValue;
    }

    clone(only?: string[]): this {

        return new (this.constructor as Constructor<this>)(copy(this.toObject(only)));
    }

    twin(attributes?: Dictionary): this {

        const clone = this.clone([ this.idName ]);

        if (attributes) {

            clone.fill(attributes);
        }

        return clone;
    }

    toObject(only: string[] = []): Dictionary {

        return (
            only.length
            ? Object.keys(this.attributes).filter((name) => only.includes(name))
            : Object.keys(this.attributes)
        ).reduce((obj, name) => {

            obj[ name ] = this.getAttribute(name);

            return obj;
        }, {});
    }

    toJSON(): Dictionary {

        return this.toObject();
    }

    valueOf(): Identifier {

        return this.idValue;
    }

    toString(): string {

        return `${this.valueOf()}`;
    }
}
