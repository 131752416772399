import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../../models/user.model';
import {ApiResourcesService} from '../../../api/api-resources.service';
import {MatDialog} from '@angular/material';
import {APPLY_ACTION, SimpleActionModalComponent} from '../../../shared/simple-action-modal/simple-action-modal.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-users-list',
  templateUrl: './admin-users-list.component.html',
  styleUrls: ['./admin-users-list.component.scss']
})
export class AdminUsersListComponent implements OnInit {

  displayedColumns: string[] = [ 'id', 'email', 'firstName', 'lastName', 'actions' ];

  @Input()
  users: UserModel[];

  @Output()
  delete = new EventEmitter<UserModel>();

  @Output()
  restore = new EventEmitter<UserModel>();

  @Input()
  showDeletedUsers;

  constructor(
    protected apiResourcesService: ApiResourcesService,
    protected dialog: MatDialog,
    private router: Router
  ) {

  }

  ngOnInit(): void {
  }

  onDelete(user: UserModel): void {

    const dialogRef = this.dialog.open(SimpleActionModalComponent, {
      width: '400px',
      data: { message: `Are you sure you want to delete user ${user.fullName}?` },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action === APPLY_ACTION) {
        this.delete.emit(user);
      }
    });
  }

  onRestore(user: UserModel): void {
    const dialogRef = this.dialog.open(SimpleActionModalComponent, {
      width: '400px',
      data: { message: `Are you sure you want to restore user ${user.fullName}?` }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action === APPLY_ACTION) {
        this.restore.emit(user);
      }
    });
  }

  detailReport(user: UserModel): void {
    this.router.navigate(['admin/', user.id]).then(r => {});
  }

}
