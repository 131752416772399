import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
export class AdminService {
    constructor(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    getAdminUser(id) {
        return this.apiResourcesService.authAdmin.user.find(id);
    }
    getAdminUsers(n, size) {
        return this.apiResourcesService.authAdmin.user.page(n, size).get();
    }
    inviteAdminUser(data) {
        return this.apiResourcesService.authAdmin.user.create(data);
    }
    updateAdminUser(data) {
        return this.apiResourcesService.authAdmin.user.update(data);
    }
    deleteAdminUser(id) {
        return this.apiResourcesService.authAdmin.user.delete(id);
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: AdminService, providedIn: "root" });
