import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { UserModel } from './user.model';
import { CurrencyModel } from './currency.model';

export class CompanyModel extends BaseModel {

    @Property()
    id: number;

    @Property({
        sourceName: 'organization_id'
    })
    organizationId: string;

    @Property()
    name: string;

    @Property({
                  sourceName: 'parent_id'
              })
    parentId: number;

    @Property({
        typeOrFactory: UserModel,
        isArray: true
    })
    managers: Array<UserModel>;

    @Property({
                  typeOrFactory: CurrencyModel
              })
    currency: CurrencyModel;

    children: this[];

    get isMasterCompany(): boolean {
        return this.managers[0].isMaster;
    }

}
