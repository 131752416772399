import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appOmitSpecialChar]'
})
export class OmitSpecialCharDirective {

  @HostListener('keypress', ['$event']) keyEvent(event: KeyboardEvent) {
    let k = event.keyCode;
    return(k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

}
