import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ReportType } from '../models/report.model';
import * as moment from 'moment';
import { Validators } from '../../common/forms/validators';
import { MY_FORMATS } from "../reports/reports.component";
export function customValidateArray() {
    return (formArray) => {
        let sum = 0;
        for (const c of formArray.controls) {
            const control = c;
            const adjControl = control.controls['adjustment'];
            sum += Number(adjControl.value);
        }
        if (sum !== 0) {
            return { not_zero: true };
        }
        else {
            return null;
        }
    };
}
const ɵ0 = MY_FORMATS;
export class AdjustmentComponent {
    constructor(fb, apiResourcesService, snackBar, changeDetectorRefs) {
        this.fb = fb;
        this.apiResourcesService = apiResourcesService;
        this.snackBar = snackBar;
        this.changeDetectorRefs = changeDetectorRefs;
        this.adjustmentsArray = [];
        this.adjustmentDiff = [];
        this.displayedColumns = [
            'accountCode',
            'accountName',
            'accountType',
            'adjustment',
            'currency',
            'fx',
            'multiply',
            'adjusted',
            'date',
            'comments',
        ];
        this.companyList = [];
        this.accountsList = [];
        this.activeDate = new FormControl({ value: moment(), disabled: true });
        this.formDate = new FormControl({ value: '', disabled: true });
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate());
    }
    get amountArrayControl() {
        return this.form.get('amountArray');
    }
    ngOnInit() {
        console.clear();
        this.formDate.setValue(moment().subtract(1, 'month').format('YYYY-MM'));
        this.createForm();
        this.getAdjustments(moment().subtract(1, 'month').format('YYYY-MM'));
        this.createFilteredForm();
    }
    createForm() {
        this.form = this.fb.group({
            amountArray: this.fb.array([], customValidateArray())
        });
    }
    createFilteredForm() {
        this.filteredForm = this.fb.group({
            accCode: new FormControl(null, []),
            accName: new FormControl(null, []),
            searchInput: new FormControl(null, []),
            companySelect: new FormControl(null, [])
        });
    }
    createItem(accId, adjustment, comment = '') {
        return this.fb.group({
            accId: this.fb.control(accId),
            adjustment: this.fb.control(adjustment, { validators: [Validators.required], updateOn: 'blur' }),
            comment: this.fb.control(comment, [Validators.maxLength(255)])
        });
    }
    getAdjustments(date, id) {
        this.progressBarMode = 'query';
        this.apiResourcesService.reportSummaryRecord
            .whereId(date)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(() => {
            this.progressBarMode = null;
        }))
            .subscribe((reports) => {
            if (!id) {
                id = reports.reports[0].company.id;
                this.accountsList = reports.reports[0].accounts;
            }
            this.companyList = [];
            this.reportsArray = reports;
            for (const report of reports.reports) {
                this.companyList.push(report.company);
            }
            this.createAccTable(id);
            this.filteredForm.get('companySelect').setValue(id);
        }, error => {
            this.dataSource = [];
            this.adjustmentsArray = [];
        });
    }
    createAccTable(id) {
        this.adjustmentsArray = [];
        const arr = this.form.controls.amountArray;
        arr.clear();
        const report = this.reportsArray.reports.find(obj => {
            return obj.companyId === id;
        });
        for (const acc of report.accounts) {
            const adjustments = {};
            adjustments.companyId = report.companyId;
            adjustments.currency = report.currency;
            adjustments.date = report.date;
            this.date = report.date;
            if (acc.accountType.toLowerCase() === 'pl') {
                adjustments.fx = report.midRate;
            }
            else {
                adjustments.fx = report.endRate;
            }
            adjustments.targetCurrency = report.targetCurrency;
            adjustments.accountCode = acc.accountCode;
            adjustments.accountName = acc.accountName;
            adjustments.accountType = acc.accountType;
            adjustments.adjusted = acc.adjusted * adjustments.fx;
            adjustments.adjustment = acc.adjustment;
            adjustments.adjustmentComment = acc.adjustmentComment;
            adjustments.balance = acc.balance;
            adjustments.id = acc.id;
            adjustments.reportingCurrency = acc.reportingCurrency;
            adjustments.multiply = acc.adjustment * adjustments.fx;
            this.targetCurrency = report.targetCurrency;
            this.adjustmentsArray.push(adjustments);
            this.activeCompanyId = report.companyId;
            this.amountArray = this.form.get('amountArray');
            this.amountArray.push(this.createItem(acc.id, acc.adjustment, acc.adjustmentComment));
        }
        this.activeCompanyName = report.company.name;
        this.dataSource = new MatTableDataSource(this.adjustmentsArray);
        this.dataSource.filterPredicate = (data, filter) => {
            return data.accountName.toString().toLowerCase().includes(filter) || data.accountCode.toString().toLowerCase().includes(filter);
        };
        this.filteredForm.get('accName').setValue(null);
        this.filteredForm.get('accCode').setValue(null);
        this.changeDetectorRefs.detectChanges();
    }
    changeCompany(companyId) {
        this.createAccTable(companyId);
    }
    chooseAccCode() {
        this.dataSource.filter = [];
        this.filteredForm.get('searchInput').setValue(null);
        const code = this.filteredForm.get('accCode').value;
        const name = this.filteredForm.get('accName').value;
        let filteredArray;
        const codeArr = [];
        const nameArr = [];
        const arrayFindObjectByProp = (arr, prop, val) => {
            return arr.find(obj => obj[prop] == val);
        };
        if (code) {
            for (let i = 0; i < code.length; i++) {
                codeArr.push(arrayFindObjectByProp(this.adjustmentsArray, 'accountCode', code[i]));
            }
        }
        if (name) {
            for (let i = 0; i < name.length; i++) {
                nameArr.push(arrayFindObjectByProp(this.adjustmentsArray, 'accountName', name[i]));
            }
        }
        const mergedArrays = codeArr.concat(nameArr);
        filteredArray = mergedArrays.filter((item, pos) => mergedArrays.indexOf(item) === pos);
        if (filteredArray && filteredArray.length) {
            this.dataSource.data = filteredArray;
        }
        else {
            this.clearSearchSelect();
        }
    }
    applyFilter(filterValue) {
        this.clearSearchSelect();
        filterValue = filterValue.value.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
    clearSearchSelect() {
        this.dataSource.data = this.adjustmentsArray;
        this.filteredForm.get('accName').setValue(null);
        this.filteredForm.get('accCode').setValue(null);
    }
    calculateAmount(target, account) {
        if (target.value && Number(+target.value)) {
            account.multiply = (+target.value) * account.fx;
            account.adjusted = (+target.value + account.reportingCurrency) * account.fx;
        }
        else {
            account.multiply = 0;
            account.adjusted = account.reportingCurrency * account.fx;
        }
    }
    focusOutFunction(target, element) {
        const formArray = this.form.controls.amountArray;
        if (!target.value || !Number(+target.value)) {
            for (const c of formArray.controls) {
                if (c.value.accId === element.id) {
                    c.get('adjustment').setValue(0);
                }
            }
        }
    }
    save() {
        this.progressBarMode = 'query';
        this.adjustmentDiff = [];
        for (const account of this.form.value.amountArray) {
            this.adjustmentDiff.push({
                account_id: account.accId,
                adjustment: Number(account.adjustment),
                comment: account.comment
            });
        }
        this.apiResourcesService.reportAdjustment
            .where('type', ReportType.Json)
            .whereSub(`/${this.activeCompanyId}`)
            .update({
            accounts: this.adjustmentDiff
        }, this.date).pipe(finalize(() => {
            this.progressBarMode = null;
        }))
            .subscribe(() => {
            this.adjustmentDiff = [];
            this.reportsArray = [];
            this.getAdjustments(this.formDate.value, this.activeCompanyId);
            //this.getAdjustments(moment().subtract(1, 'month').format('YYYY-MM'), this.activeCompanyId);
        });
    }
    downloadAdj() {
        //.whereId(moment().subtract(1, 'month').format('YYYY-MM'))
        const link = this.apiResourcesService.reportSummaryRecord
            .whereId(this.formDate.value)
            .where('type', ReportType.XlsxAdjustment)
            .authorizeUrl().url;
        window.open(link);
    }
    chosenYearHandler(normalizedYear) {
        const ctrlValue = this.activeDate.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    }
    chosenMonthHandler(normalizedMonth, datepicker) {
        const ctrlValue = this.activeDate.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
        this.getAdjustments(ctrlValue.format('YYYY-MM'));
        this.createFilteredForm();
        datepicker.close();
    }
    closePicker() {
        this.getAdjustments(this.formDate.value);
        this.createFilteredForm();
    }
}
export { ɵ0 };
