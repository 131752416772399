import { BaseModel } from '../../common/model/base.model';
import { ModelResource } from '../../common/http/model-resource';
import { ApiService } from './api.service';
import { value } from '../../common/utils';

export class ApiResource<T extends BaseModel> extends ModelResource<T> {

    protected client: ApiService;

    authorize(): this {

        return this.useAuthBearer(this.client.authTokenGetter);
    }

    authorizeUrl(): this {

        return this.where('api_token', value(this.client.authTokenGetter));
    }
}
