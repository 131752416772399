import { RouterModule, Routes } from '@angular/router';
import { BlankLayoutComponent } from '../ui/blank-layout.component';
import { GuestGuard } from '../../common/auth/guest.guard';
import { LoginComponent } from './login.component';
import { NgModule } from '@angular/core';
import { UserModel } from '../models/user.model';
import { JoinComponent } from './join.component';
import { homeOrLogin } from '../auth/home-or-login';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',
        component: BlankLayoutComponent,
        canActivate: [ GuestGuard ],
        data: {
            authFallbackUrl: homeOrLogin
        },
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'join/:token',
                component: JoinComponent
            }
        ]
    }
];

@NgModule({
              imports: [
                  RouterModule.forChild(routes)
              ],
              exports: [ RouterModule ]
          })
export class GuestRoutingModule {
}
