import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { CompanyModel } from './company.model';
import {ReportAccountModel} from './report.account.model';

export enum ReportType {
  Json = 'json',
  Xlsx = 'xlsx',
  XlsxLocalCurrency = 'xlsx-local-currency',
  XlsxConsolidated = 'xlsx-consolidated',
  XlsxAdjustment = 'xlsx-adjustment'
}

export class ReportModel extends BaseModel {

  @Property()
  date: string;

  @Property({
    sourceName: 'company_id'
  })
  companyId: number;

  @Property()
  currency: string;

  @Property({
    sourceName: 'target_currency'
  })
  targetCurrency: string;

  @Property({
    sourceName: 'end_rate'
  })
  endRate: number;

  @Property({
    sourceName: 'middle_rate'
  })
  midRate: number;

  @Property({
    typeOrFactory: CompanyModel
  })
  company: CompanyModel;

  @Property({
    typeOrFactory: ReportAccountModel,
    isArray: true
  })
  accounts: Array<ReportAccountModel>;

    @Property()
    ctr: number;
}

export class TotalModel extends BaseModel {
  @Property({
    sourceName: 'account_id'
  })
  id: number;

  @Property({
    sourceName: 'total_balance'
  })
  balance: number;
}

export class TotalReportModel extends BaseModel {
  @Property({
    typeOrFactory: ReportModel,
    isArray: true
  })
  reports: Array<ReportModel>;

  @Property({
    typeOrFactory: TotalModel,
    isArray: true
  })
  total: Array<TotalModel>;
}

export class ReportChangeModel extends BaseModel {
  @Property()
  account_id: number;

  @Property()
  balance: number;
}

export interface ReportChange {
  account_id: number;
  balance: number;
}
