<mat-toolbar *ngIf="accounts && !accounts.length" color="warn" fxLayoutAlign="space-around center">
  No accounts yet
</mat-toolbar>
<table *ngIf="accounts && accounts.length" mat-table [dataSource]="accounts">

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Account code</th>
    <td mat-cell *matCellDef="let account">{{account.code}}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Account name</th>
    <td mat-cell *matCellDef="let account">{{account.name}}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Account type</th>
    <td mat-cell *matCellDef="let account">{{account.type}}</td>
  </ng-container>

    <ng-container matColumnDef="localMapping">
        <th mat-header-cell *matHeaderCellDef>Local Mapping</th>
        <td mat-cell *matCellDef="let account">{{account.local_mapping}}</td>
    </ng-container>

    <ng-container matColumnDef="groupMapping">
        <th mat-header-cell *matHeaderCellDef>Group Mapping</th>
        <td mat-cell *matCellDef="let account">{{account.group_mapping}}</td>
    </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let account">
      <button type="button" mat-icon-button (click)="onEdit(account)" color="accent">
          <mat-icon>edit</mat-icon>
      </button>
      <button type="button" mat-icon-button (click)="onDelete(account)" color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

    <ng-container matColumnDef="disclaimer">
        <td mat-footer-cell *matFooterCellDef colspan="9">
            <div class="btn_wrapper" fxLayoutAlign="space-between center">
                <button type="button" (click)="downloadAcc()" mat-raised-button color="primary">
                    Download accounts
                </button>
            </div>
        </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container *ngIf="isMaster">
        <tr mat-footer-row *matFooterRowDef="[disclaimer]; sticky: true" class="disclaimer"></tr>
    </ng-container>
</table>
