import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';

export class AuthTokenModel extends BaseModel {

    static get idName(): string {

        return 'token';
    }

    @Property()
    token: string;
}
