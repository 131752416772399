import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../forms/validations';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy {

    protected subscriptions = new Subscription();

    message: string = null;

    form: FormGroup;
    progress: 'indeterminate';

    protected createForm() {

        this.form = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                ...Validations.getEmailRules()
            ]),
            password: new FormControl(null, [
                Validators.required,
                ...Validations.getPasswordRules()
            ])
        });

        this.subscriptions.add(this.apiService.proxyValidation(this.form).subscribe());
    }

    constructor(protected router: Router, protected authService: AuthService, protected apiService: ApiService) {
    }

    ngOnInit() {
        this.createForm();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    attempt(): void {

        this.progress = 'indeterminate';
        this.message = null;

        this.authService
            .attempt(this.form.value)
            .pipe(finalize(() => this.progress = null))
            .subscribe((user) => {
                (user) ? this.router.navigate([ user.homeRoute ]) : this.router.navigate(['/login']);
              },
   err   => this.message = 'Email or password incorrect. Please try again.');
    }
}
