<div class="adj_wrapper">
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

    <mat-toolbar>
        <!--<span>Company: {{activeCompanyName}}</span>-->
        <form [formGroup]="filteredForm" class="search_form">
            <div class="search_form_column">
                <mat-form-field class="field_wrapper" *ngIf="adjustmentsArray.length">
                    <mat-label>Account Code</mat-label>
                    <mat-select
                        formControlName="accCode"
                        multiple
                        (selectionChange)="chooseAccCode()"
                    >
                        <mat-option
                            *ngFor="let acc of accountsList"
                            [value]="acc.accountCode"
                        >
                            {{acc.accountCode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="field_wrapper" *ngIf="adjustmentsArray.length">
                    <mat-label>Account Name</mat-label>
                    <mat-select
                        formControlName="accName"
                        multiple
                        (selectionChange)="chooseAccCode()"
                    >
                        <mat-option
                            *ngFor="let acc of accountsList"
                            [value]="acc.accountName"
                        >
                            {{acc.accountName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="date_wrapper">
                    <mat-form-field>
                        <mat-label>Choose a date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="dp"
                            [formControl]="formDate"
                            [max]="tomorrow"
                        >
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker
                            #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            (closed)="closePicker()"
                            panelClass="month-picker"
                            disabled="false"
                            color="primary"
                        >
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="search_form_column" *ngIf="adjustmentsArray.length">
                <mat-form-field class="field_wrapper">
                    <mat-label>Сhoose a company</mat-label>
                    <mat-select
                        formControlName="companySelect"
                        name="companyList"
                        (selectionChange)="changeCompany($event.value)"
                    >
                        <mat-option *ngFor="let company of companyList" [value]="company.id">
                            {{company.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="field_wrapper" *ngIf="adjustmentsArray.length">
                    <input matInput  formControlName="searchInput"  (keyup)="applyFilter($event.target)" placeholder="Search Account">
                </mat-form-field>
            </div>
        </form>
    </mat-toolbar>

    <mat-toolbar *ngIf="adjustmentsArray && !adjustmentsArray.length" color="warn" fxLayoutAlign="space-around center">No adjustments yet</mat-toolbar>

    <form [formGroup]="form" fxLayout="column" class="table_form">

        <table mat-table [dataSource]="dataSource" formArrayName="amountArray" *ngIf="adjustmentsArray.length">
            <ng-container matColumnDef="accountCode">
                <th mat-header-cell *matHeaderCellDef> Account Code </th>
                <td mat-cell *matCellDef="let element"> {{element.accountCode}} </td>
            </ng-container>
            <ng-container matColumnDef="accountName">
                <th mat-header-cell *matHeaderCellDef> Account Name </th>
                <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
            </ng-container>
            <ng-container matColumnDef="accountType">
                <th mat-header-cell *matHeaderCellDef> Account Type </th>
                <td mat-cell *matCellDef="let element"> {{element.accountType}} </td>
            </ng-container>

            <ng-container matColumnDef="adjustment">
                <th mat-header-cell *matHeaderCellDef> Amount Local </th>
                <td class="amount_field" mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                    <mat-form-field>
                        <input
                            matInput
                            formControlName="adjustment"
                            appOnlyDigits
                            autocomplete="off"
                            #amount maxlength="12"
                            (keyup)='calculateAmount($event.target, element)'
                            (focusout)='focusOutFunction($event.target, element)'
                        >
                        <mat-hint align="end" *ngIf="amount.value.startsWith('-') && amount.value.indexOf('.') === -1 || !amount.value.startsWith('-') && amount.value.indexOf('.') !== -1; else elseBlock">
                            {{amount.value.length - 1}} / 10
                        </mat-hint>
                        <ng-template #elseBlock>
                            <ng-container *ngIf="amount.value.startsWith('-') && amount.value.indexOf('.') !== -1; else third">
                                <mat-hint align="end">{{amount.value.length - 2}} / 10</mat-hint>
                            </ng-container>
                        </ng-template>
                        <ng-template #third>
                            <mat-hint align="end">{{amount.value.length}} / 10</mat-hint>
                        </ng-template>

                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef> Currency </th>
                <td mat-cell *matCellDef="let element"> {{element.currency | uppercase}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="fx">
                <th mat-header-cell *matHeaderCellDef> FX </th>
                <td mat-cell *matCellDef="let element">
                    {{element.fx}}
                </td>
            </ng-container>

            <ng-container matColumnDef="multiply">
                <th mat-header-cell *matHeaderCellDef><div><span>Adjustment</span> <br> <span *ngIf="targetCurrency">({{targetCurrency}})</span></div></th>
                <td mat-cell *matCellDef="let element">
                    {{element.multiply | number}}
                </td>
            </ng-container>

            <ng-container matColumnDef="adjusted">
                <th mat-header-cell *matHeaderCellDef><div><span>Balance</span> <br> <span *ngIf="targetCurrency">({{targetCurrency}})</span></div></th>
                <td mat-cell *matCellDef="let element"> {{element.reportingCurrency | number}} </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Updated date </th>
                <td mat-cell *matCellDef="let element"> {{element.date}} </td>
            </ng-container>

            <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef > Comments </th>
                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                    <mat-form-field>
            <textarea
                matInput
                rows="2"
                formControlName="comment"
                #message maxlength="255"
            ></textarea>
                        <mat-hint align="end">{{message.value.length}} / 255</mat-hint>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="disclaimer">
                <td mat-footer-cell *matFooterCellDef colspan="10">
                    <div fxLayoutAlign="space-between center" class="disclaimer btn_wrapper">
                        <div>
                       <span class="error" *ngIf="amountArrayControl.hasError('not_zero')">
                            Total adjustments for company '{{activeCompanyName}}' should be zero
                        </span>
                        </div>

                        <div class="action_btn">
                            <button
                                [disabled]="this.progressBarMode && this.progressBarMode.length !== 0"
                                type="button"
                                mat-raised-button
                                color="primary"
                                (click)="downloadAdj()"
                            >
                                Download XLSX
                            </button>

                            <button
                                type="submit"
                                mat-raised-button
                                color="primary"
                                (click)="save()"
                                [disabled]="amountArrayControl.invalid"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky: true" class="example-second-footer-row"></tr>
        </table>

    </form>

</div>
