<div class="container">
  <mat-toolbar>
    <button
      class="btn"
      mat-raised-button
      color="primary"
      (click)="goBack()"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span fxFlex="1 1 auto"></span>
    <div class="info_user" *ngIf="user">
      <p class="name">
        {{user.fullName}}
      </p>
      <p class="email">
        Email: {{user.email}}
      </p>
    </div>
  </mat-toolbar>

  <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

  <mat-tab-group>
    <mat-tab  label="Regular Users">
      <mat-toolbar *ngIf="staff && !staff.length" color="warn" fxLayoutAlign="space-around center">No users yet</mat-toolbar>
        <table *ngIf="staff && staff.length" mat-table [dataSource]="staff">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#ID</th>
            <td mat-cell *matCellDef="let staff">{{staff.id}}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let staff">{{staff.email}}</td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let staff">{{staff.fullName}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator
          *ngIf="staff && staff.length && paginationVisibility"
          [pageSize]="perPage"
          [showFirstLastButtons]="true"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)"
        ></mat-paginator>
    </mat-tab>
    <mat-tab label="Companies">
      <mat-toolbar *ngIf="companies && !companies.length" color="warn" fxLayoutAlign="space-around center">No companies yet</mat-toolbar>
        <table *ngIf="companies && companies.length" mat-table [dataSource]="companies">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#ID</th>
            <td mat-cell *matCellDef="let company">{{company.id}}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Company Name</th>
            <td mat-cell *matCellDef="let company">{{company.name}}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Manager Name</th>
            <td mat-cell *matCellDef="let company">

                <div *ngFor="let manager of company.managers">
                    <span>{{manager.fullName}}</span>
                </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>Currency</th>
            <td mat-cell *matCellDef="let company">{{company.currency.name}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCompaniesColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedCompaniesColumns;"></tr>
        </table>
    </mat-tab>
  </mat-tab-group>

</div>
