import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CompanyModel } from '../models/company.model';
import { CompanyModifyComponent } from './company-modify/company-modify.component';
import { ApiResourcesService } from '../api/api-resources.service';
import { finalize } from 'rxjs/operators';
import {APPLY_ACTION, SimpleActionModalComponent} from '../shared/simple-action-modal/simple-action-modal.component';

@Component({
   selector: 'app-companies',
   templateUrl: './companies.component.html',
   styleUrls: [ './companies.component.scss' ]
})
export class CompaniesComponent implements OnInit {

    progressBarMode: string;
    companies: CompanyModel[] = [];

    constructor(protected apiResourcesService: ApiResourcesService, protected dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.reload();
    }

    reload(): void {

        this.progressBarMode = 'query';

        this.apiResourcesService.company
            .order('parent_id', false)
            .get()
            .pipe(finalize(() => this.progressBarMode = null))
            .subscribe((companies: CompanyModel[]) => {
                this.companies = companies;
            });
    }

    onAdd(parent): void {
        const dialogRef = this.dialog.open(CompanyModifyComponent, {
            width: '400px',
            data: { parent }
        });

        dialogRef.afterClosed().subscribe((company?: CompanyModel) => company && (this.companies = [ ...this.companies, company ]));
    }

    onEdit(company: CompanyModel): void {
        const dialogRef = this.dialog.open(CompanyModifyComponent, {
            width: '400px',
            data: { company }
        });

        dialogRef.afterClosed().subscribe((updatedCompany?) => updatedCompany && company.fillFrom(updatedCompany));
    }

    onDelete(company: CompanyModel): void {
        const dialogRef = this.dialog.open(SimpleActionModalComponent, {
          width: '400px',
          data: { message: `Are you sure you want to delete ${company.name}` }
        });

        dialogRef.afterClosed().subscribe((action) => {
          if (action === APPLY_ACTION) {
            this.apiResourcesService.company
              .delete(company.id)
              .pipe(finalize(() => this.progressBarMode = null))
              .subscribe(() => {
                this.companies = this.companies.filter((other) => !other.equal(company));
                this.reload();
              });
          }
        });
    }
}
