import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';

export enum UserRole {
    SuperUser = 'super-user',
    MasterUser = 'master-user',
    User = 'user'
}

export const HomeRoutes = new Map([
  [ UserRole.SuperUser, '/admin' ],
  [ UserRole.MasterUser, '/users' ],
  [ UserRole.User, '/reports' ]
]);

export class UserModel extends BaseModel {

    @Property()
    id: number;

    @Property({
        sourceName: 'organization_id'
    })
    organizationId: string;

    @Property()
    role: UserRole;

    @Property()
    email: string;

    @Property({
                  sourceName: 'first_name'
              })
    firstName: string;

    @Property({
                  sourceName: 'last_name'
              })
    lastName: string;

    get fullName(): string {

        return `${this.firstName} ${this.lastName}`;
    }

    get homeRoute(): string {

        return HomeRoutes.get(this.role);
    }

    get isAdmin(): boolean {

        return this.hasRole(UserRole.SuperUser);
    }

    get isMaster(): boolean {

        return this.hasRole(UserRole.MasterUser);
    }

    get isStaff(): boolean {

        return this.hasRole(UserRole.User);
    }

    hasRole(role: UserRole | UserRole[]): boolean {

        return Array.isArray(role) ? role.includes(this.role) : role === this.role;
    }
}
