/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i6 from "@angular/material/paginator";
import * as i7 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i8 from "@angular/material/toolbar";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/common";
import * as i11 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "@angular/flex-layout/flex";
import * as i17 from "@angular/flex-layout/core";
import * as i18 from "./users-list/users-list.component.ngfactory";
import * as i19 from "./users-list/users-list.component";
import * as i20 from "../api/api-resources.service";
import * as i21 from "@angular/material/dialog";
import * as i22 from "./users.component";
import * as i23 from "../auth/auth.service";
var styles_UsersComponent = [i0.styles];
var RenderType_UsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersComponent, data: {} });
export { RenderType_UsersComponent as RenderType_UsersComponent };
function View_UsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.progressBarMode; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_UsersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.handlePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatPaginator_0, i5.RenderType_MatPaginator)), i1.ɵdid(1, 245760, [[1, 4]], 0, i6.MatPaginator, [i6.MatPaginatorIntl, i1.ChangeDetectorRef], { pageIndex: [0, "pageIndex"], length: [1, "length"], pageSize: [2, "pageSize"], showFirstLastButtons: [3, "showFirstLastButtons"] }, { page: "page" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageIndex; var currVal_1 = _co.totalSize; var currVal_2 = _co.perPage; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_UsersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i7.View_MatToolbar_0, i7.RenderType_MatToolbar)), i1.ɵdid(3, 4243456, null, 1, i8.MatToolbar, [i1.ElementRef, i9.Platform, i10.DOCUMENT], null, null), i1.ɵqud(603979776, 2, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "button", [["color", "primary"], ["mat-mini-fab", ""], ["title", "Refresh"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["refresh"])), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "span", [["fxFlex", "1 1 auto"]], null, null, null, null, null)), i1.ɵdid(11, 671744, null, 0, i16.DefaultFlexDirective, [i1.ElementRef, i17.StyleUtils, i17.LAYOUT_CONFIG, i16.FlexStyleBuilder, i17.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["title", "Add"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersComponent_1)), i1.ɵdid(18, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-users-list", [], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.onDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_UsersListComponent_0, i18.RenderType_UsersListComponent)), i1.ɵdid(20, 49152, null, 0, i19.UsersListComponent, [i20.ApiResourcesService, i21.MatDialog], { users: [0, "users"] }, { delete: "delete" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersComponent_2)), i1.ɵdid(22, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.progressBarMode; var currVal_5 = "primary"; _ck(_v, 6, 0, currVal_4, currVal_5); _ck(_v, 8, 0); var currVal_8 = "1 1 auto"; _ck(_v, 11, 0, currVal_8); var currVal_11 = "primary"; _ck(_v, 13, 0, currVal_11); _ck(_v, 15, 0); var currVal_14 = _co.progressBarMode; _ck(_v, 18, 0, currVal_14); var currVal_15 = _co.users; _ck(_v, 20, 0, currVal_15); var currVal_16 = ((_co.users && _co.users.length) && _co.paginationVisibility); _ck(_v, 22, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 3)._toolbarRows.length === 0); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_6 = i1.ɵnov(_v, 8).inline; var currVal_7 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_6, currVal_7); var currVal_9 = (i1.ɵnov(_v, 13).disabled || null); var currVal_10 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 15).inline; var currVal_13 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_12, currVal_13); }); }
export function View_UsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-users", [], null, null, null, View_UsersComponent_0, RenderType_UsersComponent)), i1.ɵdid(1, 245760, null, 0, i22.UsersComponent, [i20.ApiResourcesService, i23.AuthService, i21.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsersComponentNgFactory = i1.ɵccf("app-users", i22.UsersComponent, View_UsersComponent_Host_0, {}, {}, []);
export { UsersComponentNgFactory as UsersComponentNgFactory };
