import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import { UsersComponent } from './users.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserItemComponent } from './user-item/user-item.component';
import { UserModifyComponent } from './user-modify/user-modify.component';
import { UserService } from './user.service';
import {
    MatCardModule, MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule, MatInputModule,
    MatListModule, MatPaginatorModule, MatProgressBarModule,
    MatSelectModule,
    MatTableModule
} from '@angular/material';
import { UiModule } from '../ui/ui.module';

@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  UsersRoutingModule,
                  MatDividerModule,
                  MatDialogModule,
                  MatListModule,
                  MatSelectModule,
                  UiModule,
                  MatTableModule,
                  MatGridListModule,
                  MatCardModule,
                  MatProgressBarModule,
                  MatInputModule,
                  MatCheckboxModule,
                  MatPaginatorModule
              ],
              providers: [
                  UserService
              ],
              declarations: [
                  UsersComponent,
                  UsersListComponent,
                  UserItemComponent,
                  UserModifyComponent
              ],
              exports: [],
              entryComponents: [
                  UserModifyComponent
              ]
          })
export class UsersModule {
}
