<mat-list-item class="company-item">
  <div class="info">
    <span mat-line><b>{{ node.name }}</b></span>
    <span mat-line><i>Currency: {{ node.currency.name }}</i></span>

      <div *ngFor="let manager of node.managers;">
          <span mat-line>
              Manager:
              {{ manager.fullName }};
          </span>
      </div>
  </div>
  <div fxFlex="1 1 auto"></div>
  <div class="btns_wrapper">
    <button
      mat-icon-button
      color="primary"
      (click)="addCompany(node)"
      title="Add"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      color="accent"
      (click)="editCompany(node)"
      title="Edit">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      *ngIf="!node.isMasterCompany"
      mat-icon-button
      color="warn"
      (click)="deleteCompany(node)"
      title="Delete"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-list-item>
