<mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="center center">Join</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="join()" fxLayout="column">

            <div class="mat-form-field-wrapper">
                <mat-error *errors="let message of form.get('invite_key'); subject: 'invite_key'">{{ message }}</mat-error>
            </div>

            <mat-form-field>
                <input matInput placeholder="First name" type="text" formControlName="first_name" required>
                <mat-error *errors="let message of form.get('first_name'); subject: 'first_name'">{{ message }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Last name" type="text" formControlName="last_name" required>
                <mat-error *errors="let message of form.get('last_name'); subject: 'last_name'">{{ message }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Password" type="password" formControlName="password" required autocomplete="new-password">
                <mat-error *errors="let message of form.get('password'); subject: 'password'">{{ message }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Password confirmation" type="password"
                       formControlName="password_confirmation" required autocomplete="new-password">
                <mat-error *errors="let message of form.get('password_confirmation'); subject: 'password_confirmation'">{{ message }}</mat-error>
            </mat-form-field>

            <div class="mat-input-wrapper mat-form-field-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <button *ngIf="!progressBarMode" type="submit" mat-raised-button color="primary" [disabled]="form.invalid">
                    Join
                </button>
                <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>
            </div>
        </form>
    </mat-card-content>
</mat-card>
