<div class="container">
  <mat-toolbar>
    <button mat-mini-fab color="primary" title="Refresh" (click)="reloadList()" [disabled]="progressBarMode">
      <mat-icon>refresh</mat-icon>
    </button>
    <button mat-raised-button color="primary" (click)="invite()" title="Add">
      <mat-icon>add</mat-icon>
    </button>
    <span fxFlex="1 1 auto"></span>
    <div>
      <span>
        Show deleted users:
      </span>
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        (change)="deletedUsers($event)"
      >
      </mat-slide-toggle>
    </div>
  </mat-toolbar>

  <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

  <app-admin-users-list
    [users]="users"
    (delete)="onDelete($event)"
    (restore)="onRestore($event)"
    [showDeletedUsers]="buttonsSwitcher"
  ></app-admin-users-list>
  <mat-paginator
    *ngIf="users && users.length && paginationVisibility"
    [pageSize]="perPage"
    [showFirstLastButtons]="true"
    [length]="totalSize"
    [pageIndex]="pageIndex"
    (page)="handlePage($event)"
  ></mat-paginator>
</div>
