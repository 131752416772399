<div class="simple-action-dialog">
  <h2>
    {{data?.title}}
  </h2>
  <div class="body">
    {{data?.message }}
  </div>
  <div class="actions-panel">
    <button
      mat-raised-button color="primary"
      (click)="onApplyClick()">
      {{ data?.applyText || 'Yes' }}
    </button>
    <button
      mat-raised-button color="accent"
      (click)="onCancelClick()">
      {{data?.cancelText || 'Cancel' }}
    </button>
  </div>
</div>
