import { Injectable } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { isFunction, isObject } from '../utils';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserGuard<U> extends AuthGuard {

    protected getUserMatcher(route: ActivatedRouteSnapshot): (user: U) => boolean {

        if (isObject(route.data.authUserMatcher)) {

            return (user: U): boolean => Object.keys(route.data.authUserMatcher)
                                               .every((key) => user[ key ] === route.data.authUserMatcher[ key ]);
        }

        if (isFunction(route.data.authUserMatcher)) {

            return route.data.authUserMatcher;
        }
    }

    protected check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authService
                   .user
                   .pipe(
                       map((user) => {

                           const matcher = this.getUserMatcher(route);

                           return matcher ? matcher(user) : !!user;
                       }),
                       catchError(() => of(false))
                   );
    }

    constructor(router: Router, authService: AuthService<any, any>) {

        super(router, authService);
    }
}