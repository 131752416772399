import { Routes } from '@angular/router';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { UserRole } from '../models/user.model';
import { homeOrLogin } from '../auth/home-or-login';
import { roleMatcher } from '../auth/role.matcher';
import { AdjustmentComponent } from '../adjustment/adjustment.component';
import { CurrencyComponent } from '../currency/currency.component';
const ɵ0 = {
    authUserMatcher: roleMatcher(UserRole.MasterUser),
    authFallbackUrl: homeOrLogin
}, ɵ1 = {
    authUserMatcher: roleMatcher(UserRole.MasterUser),
    authFallbackUrl: homeOrLogin
};
const routes = [
    {
        path: 'adjustment',
        component: DashboardComponent,
        canActivate: [UserGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: AdjustmentComponent,
            },
        ]
    },
    {
        path: 'currency',
        component: DashboardComponent,
        canActivate: [UserGuard],
        data: ɵ1,
        children: [
            {
                path: '',
                component: CurrencyComponent,
            }
        ]
    }
];
export class UiRoutingModule {
}
export { ɵ0, ɵ1 };
