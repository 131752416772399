import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import {
    MatDialogModule,
} from '@angular/material';
import { UiModule } from '../ui/ui.module';
import { SimpleActionModalComponent } from './simple-action-modal/simple-action-modal.component';
import { NumbersOnlyDirective } from './directives/number-only.directive';

@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  MatDialogModule,
                  UiModule,
              ],
              declarations: [
                  SimpleActionModalComponent,
                  NumbersOnlyDirective,
              ],
              exports: [
                NumbersOnlyDirective,
              ],
              entryComponents: [
                SimpleActionModalComponent,
              ]
          })
export class SharedModule {
}
