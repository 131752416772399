import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsRoutingModule } from './accounts-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import {
  MatCardModule,
  MatDialogModule,
  MatDividerModule,
  MatGridListModule, MatInputModule,
  MatListModule, MatPaginatorModule, MatProgressBarModule,
  MatSelectModule,
  MatTableModule
} from '@angular/material';
import { UiModule } from '../ui/ui.module';
import { AccountsComponent } from './accounts.component';
import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { AccountModifyComponent } from './account-modify/account-modify.component';

@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  AccountsRoutingModule,
                  MatDividerModule,
                  MatDialogModule,
                  MatListModule,
                  MatSelectModule,
                  UiModule,
                  MatTableModule,
                  MatGridListModule,
                  MatCardModule,
                  MatProgressBarModule,
                  MatInputModule,
                  MatPaginatorModule
              ],
              declarations: [
                  AccountsComponent,
                  AccountsListComponent,
                  AccountModifyComponent,
              ],
              exports: [],
              entryComponents: [
                AccountModifyComponent
              ]
          })
export class AccountsModule {
}
