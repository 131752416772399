import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { UserModel } from './user.model';
import { AuthTokenModel } from './auth-token.model';
export class AccessModel extends BaseModel {
    static get idName() {
        return 'token';
    }
}
tslib_1.__decorate([
    Property({
        typeOrFactory: UserModel
    })
], AccessModel.prototype, "user", void 0);
tslib_1.__decorate([
    Property({
        typeOrFactory: AuthTokenModel
    })
], AccessModel.prototype, "token", void 0);
