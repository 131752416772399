<mat-toolbar *ngIf="reports && !reports.length" color="warn" fxLayoutAlign="space-around center">No reports yet</mat-toolbar>

<div class="table_container">
    <table *ngIf="reports && reports.length" mat-table [dataSource]="reports" class="mat-elevation-z8">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let report">{{report.date}}</td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let report">{{report.company.name}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let report">
                <button type="button" mat-icon-button (click)="detailReport(report)" color="primary">
                  <mat-icon>info</mat-icon>
                </button>

                <button type="button" mat-icon-button (click)="download(report)" color="primary">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="disclaimer">
            <td mat-footer-cell *matFooterCellDef colspan="9">
                   <div class="btn_wrapper" fxLayoutAlign="space-between center">
                       <button type="button" (click)="downloadRates()" mat-raised-button color="primary">
                           Exchange rate
                       </button>
                      <div>

                          <ng-template [ngIf]="user.isMaster">
                              <span fxFlex="1 1 auto"></span>
                              <a [routerLink]="['/reports/general-report']" *ngIf="reports && reports.length">
                                  <button
                                      class="btn"
                                      mat-raised-button
                                      color="primary"
                                  >
                                      view TB consolidated
                                  </button>
                              </a>
                          </ng-template>

                          <button
                              type="button"
                              (click)="downloadConsolidatedReport()"
                              mat-raised-button
                              color="primary"
                          >
                              TB consolidated
                          </button>
                          <button type="button" (click)="downloadLocalReport()" mat-raised-button color="primary">
                              Local currency TBs
                          </button>
                      </div>
                   </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <ng-container *ngIf="isMaster">
            <tr mat-footer-row *matFooterRowDef="[disclaimer]; sticky: true" class="disclaimer"></tr>
        </ng-container>

    </table>
</div>
