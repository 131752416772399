import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyModel } from '../../models/company.model';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import {finalize, tap} from 'rxjs/operators';
import { CurrencyModel } from '../../models/currency.model';
import { UserModel } from '../../models/user.model';
import { ApiResourcesService } from '../../api/api-resources.service';
import { ApiFormComponent } from '../../forms/api-form.component';
import { voider } from '../../../common/utils';
import { Dictionary } from '../../../common/types';
import {PaginatedItems} from '../../../common/http/types';
import {MatAutocomplete} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

export interface CompanyModifyDialogData {
    company: CompanyModel;
    parent?: CompanyModel;
    title?: string;
}

@Component({
   selector: 'app-company-modify',
   templateUrl: './company-modify.component.html',
   styleUrls: [ './company-modify.component.scss' ]
})
export class CompanyModifyComponent extends ApiFormComponent implements OnInit {

    fieldProgressBarModes: Dictionary<string> = {};
    title: string;
    managerId: number;
    company: CompanyModel;
    parent: CompanyModel;
    currencies: CurrencyModel[] = [];
    parents: CompanyModel[] = [];
    managers: UserModel[] = [];

    /*visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    activeManagersList: Array<UserModel> = [];
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;*/

    get companyId(): number {

        return this.company && this.company.id;
    }

    get parentId(): number {

        return this.parent && this.parent.id;
    }

    get isNew(): boolean {

        return !this.companyId;
    }

    protected withFieldProgressBar<T extends Observable<any>>(field: string, subject: T): T {

        this.fieldProgressBarModes[ field ] = 'query';

        return subject.pipe(finalize(() => this.fieldProgressBarModes[ field ] = null)) as T;
    }

    protected createForm(): FormGroup {

        const formGroup = new FormGroup({
            name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            currency_id: new FormControl(null, [
                Validators.required
            ])
        });

        if (this.isNew) {
            formGroup.addControl('parent_id', new FormControl(this.parentId, [ Validators.required ]));
        }
        if (this.isNew || !this.company.isMasterCompany) {
            formGroup.addControl('managers_ids', new FormControl(null, [ Validators.required ]));
        }

        return formGroup;
    }

    protected updateFormValue(): void {

        if (!this.isNew) {
            /*if (!this.managers.find((manager) => manager.equal(this.company.managers[0]))) {
                //this.managers.push(this.company.managers);
            }*/

            this.form.controls.name.setValue(this.company.name);
            this.form.controls.currency_id.setValue(this.company.currency.id);

            if (this.form.controls.managers_ids) {
                const currentCompanyManagers = [];
                for (const manager of this.company.managers) {
                    currentCompanyManagers.push(manager.id);
                }
                this.form.controls.managers_ids.setValue(currentCompanyManagers);
            }

        }
    }

    protected reloadCurrencies(): Promise<void> {

        return this.withFieldProgressBar(
            'currency_id',
            this.apiResourcesService.selectedCurrency
                .get()
                .pipe(
                    tap((currencies: CurrencyModel[]) => this.currencies = currencies)
                )
        )
                   .toPromise()
                   .then(voider);
    }


    protected reloadParents(): Promise<void> {

        return this.withFieldProgressBar(
            'parent_id',
            this.apiResourcesService.company
                .get()
                .pipe(
                    tap((companies: CompanyModel[]) => {
                      this.parents = companies;
                    })
                )
        )
                   .toPromise()
                   .then(voider);
    }


    protected reloadManagers(): Promise<void> {

      return this.withFieldProgressBar(
            'managers_ids',
            this.apiResourcesService.staff
                .get()
                .pipe(
                    tap((managers: PaginatedItems<any>) => {
                      this.managers = managers.data;
                    })
                )
        )
                   .toPromise()
                   .then(voider);
    }

    constructor(
        protected dialogRef: MatDialogRef<CompanyModifyComponent>,
        protected apiResourcesService: ApiResourcesService,
        apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) data: CompanyModifyDialogData
    ) {

        super(apiService);

        this.company = data && data.company;
        this.parent = data && data.parent;
        this.title = data && data.title || (this.isNew ? 'Create company' : 'Edit company');
    }

    ngOnInit(): void {

        this.form = this.createForm();
        this.subscribeOnErrors(this.form);

        const promises = [];

        promises.push(this.reloadCurrencies());

        if (this.form.contains('parent_id')) {

            promises.push(this.reloadParents());
        }
        if (this.form.contains('managers_ids')) {

            promises.push(this.reloadManagers());
        }

        Promise.all(promises).then(
          () => this.updateFormValue()
        );

    }

    /*displayFn(manager): string | undefined {
      return manager ? manager.fullName : undefined;
    }

    onSearchChange(searchValue: string): void {
      this.apiResourcesService.staff
        .where('manager', 0)
        .where('query', searchValue)
        .get()
        .subscribe((managers: PaginatedItems<any>) => {
          this.managers = managers.data;
        });
    }

    selectedManager(manager: any): void {
        this.managerId = manager.id;
    }*/

    cancel(): void {
        this.dialogRef.close();
    }

    save(): void {
        const formValue = {
          name: this.form.value.name,
          currency_id: this.form.value.currency_id,
          parent_id:  this.form.value.parent_id,
          managers_ids: this.form.value.managers_ids
        };

        if (!this.form.contains('managers_ids')) {
            formValue.managers_ids = [];
            for(const manager of this.company.managers) {
                formValue.managers_ids.push(manager.id);
            }
        }

        this.withProgressBar(
            this.apiResourcesService.company[ this.isNew ? 'create' : 'update' ](formValue, this.companyId)
        )
          .subscribe((company: CompanyModel) => this.dialogRef.close(company));
    }

}
