import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import {
  MatCardModule, MatDialogModule,
  MatGridListModule, MatInputModule, MatListModule, MatMenuModule,
  MatPaginatorModule, MatProgressBarModule, MatSlideToggleModule, MatTableModule, MatTabsModule,
} from '@angular/material';
import { UiModule } from '../ui/ui.module';
import {AdminRoutingModule} from './admin-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminUsersListComponent } from './admin-users/admin-users-list/admin-users-list.component';
import {AdminService} from './admin.service';
import { AdminUserModifyComponent } from './admin-users/admin-user-modify/admin-user-modify.component';
import { AdminUsersInfoComponent } from './admin-users/admin-users-info/admin-users-info.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AuthModule,
        ApiModule,
        AdminRoutingModule,
        MatGridListModule,
        MatCardModule,
        MatProgressBarModule,
        MatPaginatorModule,
        UiModule,
        MatMenuModule,
        MatListModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatSlideToggleModule,
        MatTabsModule
    ],
    providers: [
      AdminService
    ],
    declarations: [
        AdminDashboardComponent,
        AdminUsersComponent,
        AdminUsersListComponent,
        AdminUserModifyComponent,
        AdminUsersInfoComponent
    ],
    exports: [],
    entryComponents: [
      AdminUserModifyComponent
    ]
})
export class AdminModule {

}
