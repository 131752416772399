<div class="container">
  <mat-toolbar>
    <button
      class="btn"
      mat-raised-button
      color="primary"
      (click)="goBack()"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span fxFlex="1 1 auto"></span>
    <h2 class="mat-h2" *ngIf="company">
      Trial balance: {{company.name}}
    </h2>
    <span fxFlex="1 1 auto"></span>
    <div class="converter_wrapper" *ngIf="report">
      <p>
        AVG: {{report.midRate}}
      </p>
      <p>
        YTD: {{report.endRate}}
      </p>
    </div>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

  <table *ngIf="reportAccounts && reportAccounts.length" mat-table [dataSource]="reportAccounts">

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Account code</th>
      <td mat-cell *matCellDef="let element">{{element.accountCode}}</td>
        <td mat-footer-cell *matFooterCellDef><b>CTR</b></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Account name</th>
      <td mat-cell *matCellDef="let element">{{element.accountName}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Account type</th>
      <td mat-cell *matCellDef="let element">{{element.accountType}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pbc">
      <th mat-header-cell *matHeaderCellDef>PBC <br> ({{report.currency}}) </th>
      <td mat-cell *matCellDef="let element">
        {{element.balance}}
      </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="adj">
      <th mat-header-cell *matHeaderCellDef>ADJ <br> ({{report.currency}}) </th>
      <td mat-cell *matCellDef="let element">
        {{element.adjustment}}
      </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="adjusted">
      <th mat-header-cell *matHeaderCellDef>Adjusted <br> ({{report.currency}}) </th>
      <td mat-cell *matCellDef="let element">
        {{element.adjusted}}
      </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Reporting currency <br> ({{report.targetCurrency}}) </th>
      <td mat-cell *matCellDef="let element">
        {{element.reportingCurrency}}
      </td>

        <td mat-footer-cell *matFooterCellDef> {{report.ctr | number}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

</div>
