import { NgModule } from '@angular/core';
import { GuestGuard } from './guest.guard';
import { UserGuard } from './user.guard';
import { AuthService } from './auth.service';

@NgModule({
              providers: [
                  GuestGuard,
                  UserGuard,
                  {
                      provide: AuthService, useFactory: () => {

                          throw new Error('AuthService should be extended and provided');
                      }
                  }
              ]
          })
export class AuthModule {
}
