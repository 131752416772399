import {Component, OnInit, ViewChild} from '@angular/core';
import {UserModel} from '../../models/user.model';
import {MatDialog, MatPaginator, MatSlideToggleChange, MatTableDataSource, PageEvent} from '@angular/material';
import {ApiResourcesService} from '../../api/api-resources.service';
import {finalize} from 'rxjs/operators';
import {PaginatedItems} from '../../../common/http/types';
import {AdminUserModifyComponent} from './admin-user-modify/admin-user-modify.component';
import {AdminService} from '../admin.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  progressBarMode: string;
  users: any;
  showDeletedUsers = true;
  buttonsSwitcher = true;

  perPage = 20;
  pageIndex = 0;
  totalSize;
  dataSource: any;
  paginationVisibility = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    protected apiResourcesService: ApiResourcesService,
    protected dialog: MatDialog,
    protected adminService: AdminService,
  ) {
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    (this.showDeletedUsers) ? this.getUsers() : this.getDeletedUsers();
  }

  handlePage(e: PageEvent): void {
    this.pageIndex = e.pageIndex;
    this.perPage = e.pageSize;
    this.reloadList();
  }

  deletedUsers(event: MatSlideToggleChange) {
    this.pageIndex = 0;
    this.showDeletedUsers = !this.showDeletedUsers;
    this.reloadList();
  }

  getUsers(): void {
    this.progressBarMode = 'query';

    this.adminService.getAdminUsers(this.pageIndex + 1, this.perPage)
      .pipe(finalize(() => {
        this.progressBarMode = null;
        this.showDeletedUsers = true;
      }))
      .subscribe((users: PaginatedItems<any>) => {
        this.users = users.data;
        this.dataSource = new MatTableDataSource<any>(this.users);
        this.dataSource.paginator = this.paginator;
        this.perPage = users.meta.per_page;
        this.totalSize = users.meta.total;
        this.pageIndex = users.meta.current_page - 1;

        this.buttonsSwitcher = true;

        this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
      });

  }

  getDeletedUsers(): void {
    this.progressBarMode = 'query';

    this.apiResourcesService.authAdmin.user
      .where('deleted', 1)
      .page(this.pageIndex + 1, this.perPage)
      .get()
      .pipe(finalize(() => {
        this.progressBarMode = null;
        this.showDeletedUsers = false;
      }))
      .subscribe((users: PaginatedItems<any>) => {
        this.users = users.data;
        this.dataSource = new MatTableDataSource<any>(this.users);
        this.dataSource.paginator = this.paginator;
        this.perPage = users.meta.per_page;
        this.totalSize = users.meta.total;
        this.pageIndex = users.meta.current_page - 1;

        this.buttonsSwitcher = false;

        this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
      });
  }

  invite(): void {

    const dialogRef = this.dialog.open(AdminUserModifyComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((user?: UserModel) => {
      if (user) {
        this.users = [ ...this.users, user ];
        this.reloadList();
      }
    });

  }

  onRestore(user: UserModel): void {
    this.progressBarMode = 'indeterminate';

    this.apiResourcesService.authAdmin.restore
      .where('id', user.id)
      .update(user.toObject(), user.id)
      .subscribe(() => {
        this.users = this.users.filter((other) => !other.equal(user));

        if ((this.totalSize - 1) <= this.perPage) {
          this.pageIndex = 0;
        }
        this.getDeletedUsers();
      });
  }

  onDelete(user: UserModel): void {

    this.progressBarMode = 'indeterminate';

    this.adminService.deleteAdminUser(user.id)
      .pipe(finalize(() => this.progressBarMode = null))
      .subscribe(() => {
        this.users = this.users.filter((other) => !other.equal(user));

        if ((this.totalSize - 1) <= this.perPage) {
          this.pageIndex = 0;
        }
        this.reloadList();
      });

  }

}
