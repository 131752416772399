<div class="content">
    <mat-toolbar color="primary">
        <span class="brand">{{ appName }}</span>
        <button type="button" mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex="1 1 auto"></span>
        <ng-content select=".toolbar"></ng-content>
    </mat-toolbar>
    <mat-sidenav-container>
        <mat-sidenav #sidenav mode="side" [opened]="true" [fixedInViewport]="true" [fixedTopGap]="64">
            <ng-content select=".sidenav"></ng-content>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>