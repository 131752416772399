import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
export class AuthTokenModel extends BaseModel {
    static get idName() {
        return 'token';
    }
}
tslib_1.__decorate([
    Property()
], AuthTokenModel.prototype, "token", void 0);
