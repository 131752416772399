import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appOnlyDigits]'
})
export class OnlyDigitsDirective {
  private regex: RegExp = new RegExp('^(-?[0-9][0-9.]{0,9}|0)$');
  private regexWithDot: RegExp = new RegExp('^(-?[0-9.][0-9.]{0,10}|0)$');
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowRight', 'ArrowLeft'];
  private oldValue = '';

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const current: any = this.el.nativeElement.value;
    let key = event.key;
    let next;
    if (key === '-' && event.target.selectionStart === 0 && this.charCount(current, '-') !== 1) {

    } else {
        next = current.concat(event.key);
    }

    setTimeout(function () {
      key = event.key;
      if (key === '-' && event.target.selectionStart === 0) {
        this.el.nativeElement.value = key + this.el.nativeElement.value;
      }
    }.bind(this));

    if (!next && key === '-' && event.target.selectionStart === 0) {
      next = event.key + current;
    }
    /*if (key === '-' && event.target.selectionStart === 0 && this.charCount(next, '-') !== 1) {
      next = event.key + current;
    }*/

    if (key === '.' && event.target.selectionStart === 1) {
      next = current + event.key;
    }

    if (+next || next === '-' || next === '0' || next === '0.' || next === '-0.' || next === '-0') {
        if (next.indexOf('.') !== -1) {
            if (this.regexWithDot.test(next)) {
                this.oldValue = next;
            } else {
                return false;
            }
        } else {
            if (this.regex.test(next)) {
                this.oldValue = next;
            } else {
                return false;
            }
        }
    } else {
      if (this.specialKeys.indexOf(event.key) === -1) {
        return false;
      }
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event) {
      if (+event.target.value === 0) {
          event.target.value = '';
      }
  }

  charCount(str, letter) {
    let letterCount = 0;
    for (let position = 0; position < str.length; position++) {
      if (str.charAt(position) === letter) {
        letterCount += 1;
      }
    }
    return letterCount;
  }

}
