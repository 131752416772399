<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="company-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <app-company-item
        [node]="node"
        (add)="onAdd($event)"
        (edit)="onEdit($event)"
        (delete)="onDelete($event)"
      ></app-company-item>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <app-company-item
          [node]="node"
          (add)="onAdd($event)"
          (edit)="onEdit($event)"
          (delete)="onDelete($event)"
        ></app-company-item>
      </div>
      <ul [class.company-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
  </mat-nested-tree-node>
</mat-tree>
