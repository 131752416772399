import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Directive({
               selector: 'input[type=file][formControl],input[type=file][formControlName],input[type=file][ngModel]',
               providers: [
                   {
                       provide: NG_VALUE_ACCESSOR,
                       useExisting: forwardRef(() => FileControlValueAccessorDirective),
                       multi: true
                   }
               ]
           })
export class FileControlValueAccessorDirective implements ControlValueAccessor {

    @HostListener('change', [ '$event.target.files' ])
    onChange = (files: FileList) => {
    };
    @HostListener('blur')
    onTouched = () => {
    };

    constructor(protected renderer: Renderer2, protected elementRef: ElementRef) {
    }

    writeValue(value: any): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
    }

    registerOnChange(fn: (files: FileList) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }
}
