import { BaseModel } from '../model/base.model';
import { Resource } from './resource';
import { Constructor, Dictionary, Fabricator } from '../types';
import { RestClient } from './types';
import { ModelFactory } from '../model/model.factory';

export class ModelResource<T extends BaseModel> extends Resource<T> {

    protected factory: ModelFactory<T>;

    protected get fabricator(): Fabricator<T> {

        return (attributes: Dictionary) => this.factory.create(attributes);
    }

    constructor(client: RestClient, name: string, protected model: Constructor<T>, parents: Resource<T>[] = []) {

        super(client, name, parents);

        this.factory = new ModelFactory(this.model);
    }
}
