import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
export var UserRole;
(function (UserRole) {
    UserRole["SuperUser"] = "super-user";
    UserRole["MasterUser"] = "master-user";
    UserRole["User"] = "user";
})(UserRole || (UserRole = {}));
export const HomeRoutes = new Map([
    [UserRole.SuperUser, '/admin'],
    [UserRole.MasterUser, '/users'],
    [UserRole.User, '/reports']
]);
export class UserModel extends BaseModel {
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
    get homeRoute() {
        return HomeRoutes.get(this.role);
    }
    get isAdmin() {
        return this.hasRole(UserRole.SuperUser);
    }
    get isMaster() {
        return this.hasRole(UserRole.MasterUser);
    }
    get isStaff() {
        return this.hasRole(UserRole.User);
    }
    hasRole(role) {
        return Array.isArray(role) ? role.includes(this.role) : role === this.role;
    }
}
tslib_1.__decorate([
    Property()
], UserModel.prototype, "id", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'organization_id'
    })
], UserModel.prototype, "organizationId", void 0);
tslib_1.__decorate([
    Property()
], UserModel.prototype, "role", void 0);
tslib_1.__decorate([
    Property()
], UserModel.prototype, "email", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'first_name'
    })
], UserModel.prototype, "firstName", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'last_name'
    })
], UserModel.prototype, "lastName", void 0);
