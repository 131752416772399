import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';

export class ReportAccountModel extends BaseModel {

  @Property({
    sourceName: 'account_id'
  })
  id: number;

  @Property({
    sourceName: 'account_code'
  })
  accountCode: number;

  @Property({
    sourceName: 'account_name'
  })
  accountName: string;

  @Property({
    sourceName: 'account_type'
  })
  accountType: string;

  @Property()
  balance: number;

  @Property()
  adjusted: number;

  @Property()
  adjustment: number;

  @Property({
    sourceName: 'reporting_currency'
  })
  reportingCurrency: number;

  @Property({
    sourceName: 'adjustment_comment'
  })
  adjustmentComment: string;
}
