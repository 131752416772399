import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { GuestRoutingModule } from './guest-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import { UiModule } from '../ui/ui.module';
import { JoinComponent } from './join.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';

@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  UiModule,
                  MatProgressBarModule,
                  MatCardModule,
                  MatInputModule,
                  GuestRoutingModule
              ],
              declarations: [
                  LoginComponent,
                  JoinComponent
              ]
          })
export class GuestModule {
}
