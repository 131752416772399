<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">

        <mat-form-field>
            <input matInput placeholder="Email" type="email" formControlName="email" required autocomplete="new-email">
            <mat-error *errors="let message of form.get('email'); subject: 'email'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="First name" type="text" formControlName="first_name" required>
            <mat-error *errors="let message of form.get('first_name'); subject: 'first_name'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Last name" type="text" formControlName="last_name" required>
            <mat-error *errors="let message of form.get('last_name'); subject: 'last_name'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Password" type="password" formControlName="password"
                   autocomplete="new-password" required>
            <mat-error *errors="let message of form.get('password'); subject: 'password'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Password confirmation" type="password" formControlName="password_confirmation"
                   autocomplete="new-password" required>
            <mat-error
                    *errors="let message of form.get('password_confirmation'); subject: 'password_confirmation'">{{ message }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="form.get('password_passworded')">
            <input matInput placeholder="Current password" type="password" formControlName="password_passworded"
                   [required]="!!form.get('password').value" autocomplete="current-password">
            <mat-error
                    *errors="let message of form.get('password_passworded'); subject: 'current_password'">{{ message }}</mat-error>
        </mat-form-field>

        <div *ngIf="form.get('master_checkbox')">
            <mat-checkbox
                formControlName="master_checkbox"
                labelPosition="after"
            >
                Create master user
            </mat-checkbox>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="!progressBarMode">
        <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">Save</button>
        <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
    </ng-container>
    <mat-progress-bar *ngIf="progressBarMode"  [mode]="progressBarMode"></mat-progress-bar>
</mat-dialog-actions>
