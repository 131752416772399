<div class="container">
    <mat-progress-bar *ngIf="progressBarMode" [mode]="progressBarMode"></mat-progress-bar>

    <form [formGroup]="form" fxLayout="column" class="currency_table" >

        <div
            formArrayName='currencyArray'
            class="currency_wrapper"
        >
            <div class="currency_item" *ngFor="let item of currencyList; let i = index" [formGroupName]="i">
                <mat-checkbox
                    formControlName="active"
                >{{item.name}} ({{item.shortName}})</mat-checkbox>
            </div>
        </div>

        <mat-toolbar fxLayoutAlign="end center">
            <button
                type="submit"
                mat-raised-button
                color="primary"
                (click)="save()"
                *ngIf="currencyArray && currencyArray.length"
            >
                Update
            </button>
        </mat-toolbar>

    </form>
</div>
