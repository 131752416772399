import { Injectable } from '@angular/core';
import {ApiResourcesService} from '../api/api-resources.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  getAdminUser(id) {

    return this.apiResourcesService.authAdmin.user.find(id);
  }

  getAdminUsers(n, size) {

    return this.apiResourcesService.authAdmin.user.page(n, size).get();
  }

  inviteAdminUser(data) {

    return this.apiResourcesService.authAdmin.user.create(data);
  }

  updateAdminUser(data) {

    return this.apiResourcesService.authAdmin.user.update(data);
  }

  deleteAdminUser(id) {

    return this.apiResourcesService.authAdmin.user.delete(id);
  }

  constructor(protected apiResourcesService: ApiResourcesService) {
  }
}
