import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportType, TotalModel, TotalReportModel } from '../../models/report.model';
import { MatPaginator } from '@angular/material';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { FormControl } from "@angular/forms";
import { MY_FORMATS } from "../reports.component";
const ɵ0 = MY_FORMATS;
export class GeneralReportComponent {
    constructor(dialog, apiResourcesService, router, location) {
        this.dialog = dialog;
        this.apiResourcesService = apiResourcesService;
        this.router = router;
        this.location = location;
        this.displayedColumns = ['code', 'name', 'type'];
        this.displayedDynamicColumns = [];
        this.accounts = [];
        this.totalCtr = 0;
        this.perPage = 100;
        this.pageIndex = 0;
        this.paginationVisibility = true;
        this.subscriptions = new Subscription();
        this.date = new FormControl({ value: moment(), disabled: true });
        this.formDate = new FormControl({ value: '', disabled: true });
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate());
    }
    ngOnInit() {
        this.getSummaryReportData(moment().subtract(1, 'month').format('YYYY-MM'));
        this.formDate.setValue(moment().subtract(1, 'month').format('YYYY-MM'));
    }
    getSummaryReportData(data) {
        this.progressBarMode = 'query';
        this.apiResourcesService.reportSummaryRecord
            .whereId(data)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(() => {
            this.progressBarMode = null;
        }))
            .subscribe((totalReport) => {
            if (totalReport.reports) {
                this.reportsArray = totalReport.reports;
                for (let report of this.reportsArray) {
                    for (let acc of report.accounts) {
                        let index = this.accounts.findIndex(x => x.id === acc.id);
                        if (index === -1) {
                            this.accounts.push(acc);
                        }
                    }
                }
                this.total = totalReport.total;
                this.targetCurrency = this.reportsArray[0].targetCurrency;
                for (const report of this.reportsArray) {
                    this.displayedDynamicColumns.push(report.company.name);
                    this.totalCtr += report.ctr;
                }
                this.accounts.sort((a, b) => {
                    return (a.id - b.id);
                });
                for (let report of this.reportsArray) {
                    for (let acc of this.accounts) {
                        let index = report.accounts.findIndex(x => x.id === acc.id);
                        if (index === -1) {
                            let faceAcc = {
                                id: acc.id,
                                balance: 0
                            };
                            report.accounts.push(faceAcc);
                        }
                    }
                    report.accounts.sort(function (a, b) {
                        return (a.id - b.id);
                    });
                }
            }
        });
    }
    download() {
        const link = this.apiResourcesService.reportSummaryRecord
            //.whereId(moment().subtract(1, 'month').format('YYYY-MM'))
            .whereId(this.formDate.value)
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;
        window.open(link);
    }
    goBack() {
        this.location.back();
    }
    chosenYearHandler(normalizedYear) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    }
    chosenMonthHandler(normalizedMonth, datepicker) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(ctrlValue.format('YYYY-MM'));
        datepicker.close();
    }
    closePicker() {
        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(this.formDate.value);
    }
}
export { ɵ0 };
