import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material';
import { NotificationsService } from './notifications.service';

@NgModule({
              imports: [
                  MatSnackBarModule
              ],
              providers: [
                  NotificationsService
              ]
          })
export class NotificationsModule {
}
