import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
export class CompanyService {
    constructor(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    getCompany(id) {
        return this.apiResourcesService.company.find(id);
    }
    getCompanies() {
        return this.apiResourcesService.company.get();
    }
    createCompany(company) {
        return this.apiResourcesService.company.create(company);
    }
    editCompany(id, company) {
        return this.apiResourcesService.company.update(company, id);
    }
    deleteCompany(id) {
        return this.apiResourcesService.company.delete(id);
    }
}
CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: CompanyService, providedIn: "root" });
