import {Injectable} from '@angular/core';
import {ApiResourcesService} from '../api/api-resources.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  getUser(id) {

    return this.apiResourcesService.staff.find(id);
  }

  getUsers() {

    return this.apiResourcesService.staff.get();
  }

    createMasterUser(data) {

        return this.apiResourcesService.masterUser.create(data);
    }

  createUser(data) {

    return this.apiResourcesService.staff.create(data);
  }

  updateUser(data) {

    return this.apiResourcesService.auth.user.update(data);
  }

  deleteUser(id) {

    return this.apiResourcesService.staff.delete(id);
  }

  constructor(protected apiResourcesService: ApiResourcesService) {
  }
}
