import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
               selector: 'app-dashboard-layout',
               templateUrl: './dashboard-layout.component.html',
               styleUrls: [ './dashboard-layout.component.scss' ]
           })
export class DashboardLayoutComponent {

    appName = environment.appName;
}
