import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {Observable, Observer, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {UserModel} from '../../../models/user.model';
import {AdminService} from '../../admin.service';
import {PaginatedItems} from '../../../../common/http/types';
import {ApiResourcesService} from '../../../api/api-resources.service';
import {MatPaginator, MatTableDataSource, PageEvent} from '@angular/material';
import {CompanyModel} from '../../../models/company.model';

@Component({
  selector: 'app-admin-users-info',
  templateUrl: './admin-users-info.component.html',
  styleUrls: ['./admin-users-info.component.scss']
})
export class AdminUsersInfoComponent implements OnInit {

  displayedColumns: string[] = [ 'id', 'email', 'fullName' ];
  displayedCompaniesColumns: string[] = [ 'id', 'name', 'email', 'currency' ];

  progressBarMode: string;
  params: any;
  private routeSub: Subscription;
  user: UserModel;
  staff: UserModel[];
  companies: CompanyModel[];

  perPage = 20;
  pageIndex = 0;
  totalSize;
  dataSource: any;
  paginationVisibility = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  asyncTabs: Observable<any[]>;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    protected adminService: AdminService,
    protected apiResourcesService: ApiResourcesService,
  ) {
    this.asyncTabs = new Observable((observer: Observer<any[]>) => {
      setTimeout(() => {
        observer.next([
          {label: 'Regular Users', content: this.staff},
          {label: 'Companies', content: this.companies}
        ]);
      }, 1000);
    });
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.params = params;
    });

    this.getAdminUserInfo();

    this.getManagerUsersList();
    this.getManagerCompanyList();
  }

  handlePage(e: PageEvent): void {
    this.pageIndex = e.pageIndex;
    this.perPage = e.pageSize;
    this.getManagerUsersList();
  }

  getAdminUserInfo(): void {
    this.progressBarMode = 'query';

    this.adminService.getAdminUser(this.params.id)
      .pipe(finalize(() => {
        this.progressBarMode = null;
      }))
      .subscribe((user) => {
        this.user = user;
      });
  }

  getManagerUsersList(): void {
    this.apiResourcesService.adminStaffList
      .where('id', this.params.id)
      .page(this.pageIndex + 1, this.perPage)
      .get()
      .pipe(finalize(() => this.progressBarMode = null))
      .subscribe((staff: PaginatedItems<any>) => {
        this.staff = staff.data;

        this.dataSource = new MatTableDataSource<any>(this.staff);
        this.dataSource.paginator = this.paginator;
        this.perPage = staff.meta.per_page;
        this.totalSize = staff.meta.total;
        this.pageIndex = staff.meta.current_page - 1;

        this.totalSize <= this.perPage ? this.paginationVisibility = false : this.paginationVisibility = true;
      });
  }

  getManagerCompanyList(): void {
    this.apiResourcesService.adminCompanyList
      .where('id', this.params.id)
      .get()
      .pipe(finalize(() => this.progressBarMode = null))
      .subscribe((companies: CompanyModel[]) => {
        this.companies = companies;
      });
  }

  goBack(): void {
    this.location.back();
  }

}
