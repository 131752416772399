import { humanize } from 'inflect';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { isObject } from '../utils';
import { Dictionary } from '../types';

export interface TemplatesDictionary extends Dictionary<string> {
}

export interface SubjectTemplatesDictionary extends Dictionary<TemplatesDictionary> {
}

export interface TemplatesConfig {
    templates?: TemplatesDictionary;
    subjectTemplates?: SubjectTemplatesDictionary;
}

export const VALIDATION_RENDERER_TEMPLATES_CONFIG = new InjectionToken('validation-renderer-templates-config');

@Injectable()
export class ValidationRenderer {

    protected defaultTemplate = '\'{{ validator }}\' validation failed';
    protected templates: TemplatesDictionary = {};
    protected subjectTemplates: SubjectTemplatesDictionary = {};

    protected renderTemplate(template: string, context: object): string {

        return template.replace(/\{\{\s*(\w+)\s*\}\}/g, (replace: string, key: string): string => {

            return context[ key ] || replace;
        });
    }

    constructor(@Optional() @Inject(VALIDATION_RENDERER_TEMPLATES_CONFIG)templatesConfig: TemplatesConfig) {

        if (templatesConfig) {
            if (templatesConfig.templates) {

                this.templates = templatesConfig.templates;
            }
            if (templatesConfig.subjectTemplates) {

                this.subjectTemplates = templatesConfig.subjectTemplates;
            }
        }
    }

    getTemplatesForSubject(subject: string): TemplatesDictionary {

        return this.subjectTemplates[ subject ] || this.templates;
    }

    getTemplate(subject: string, validator: string): string {

        return this.getTemplatesForSubject(subject)[ validator ] || this.defaultTemplate;
    }

    render(subject: string, validator: string, context?: any): string {

        context = isObject(context) ? context : {};

        if (context.message) {

            return `${context.message}`;
        }

        const templateContext = Object.assign({}, context, { subject: humanize(subject), validator });
        const template = this.getTemplate(subject, validator);

        return this.renderTemplate(template, templateContext);
    }
}
