import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
export class CurrencyModel extends BaseModel {
}
tslib_1.__decorate([
    Property()
], CurrencyModel.prototype, "id", void 0);
tslib_1.__decorate([
    Property()
], CurrencyModel.prototype, "name", void 0);
tslib_1.__decorate([
    Property({
        sourceName: 'short_name'
    })
], CurrencyModel.prototype, "shortName", void 0);
tslib_1.__decorate([
    Property()
], CurrencyModel.prototype, "selected", void 0);
