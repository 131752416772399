import { ApiService } from './api.service';
import { ApiResource } from './api.resource';
import { AccessModel } from '../models/access.model';
import { ResourceAction } from '../../common/http/types';
import { UserModel } from '../models/user.model';
import { Injectable } from '@angular/core';
import { CompanyModel } from '../models/company.model';
import { CurrencyModel } from '../models/currency.model';
import { AccountModel } from '../models/account.model';
import {ReportModel, TotalReportModel} from '../models/report.model';

@Injectable()
export class ApiResourcesService {

    get auth() {

        const apiService = this.apiService;
        const prefix = 'auth';

        return {
            get access(): ApiResource<AccessModel> {

                return new ApiResource(apiService, `${prefix}/access`, AccessModel).when(ResourceAction.Delete, (self) => self.authorize());
            },
            get user(): ApiResource<UserModel> {

                return new ApiResource(apiService, `${prefix}/user`, UserModel).when([ ResourceAction.Get, ResourceAction.Update ],
                                                                                     (self) => self.authorize());
            }
        };
    }

    get authAdmin() {
      const adminPrefix = 'admin';
      const apiService = this.apiService;

      return {
        get access(): ApiResource<AccessModel> {

          return new ApiResource(apiService, `${adminPrefix}/access`, AccessModel).when(ResourceAction.Delete, (self) => self.authorize());
        },
        get user(): ApiResource<UserModel> {
          return new ApiResource(apiService, `${adminPrefix}/user`, UserModel).when([
            ResourceAction.Get,
            ResourceAction.Delete,
            ResourceAction.Create,
            ResourceAction.Update
          ],
    (self) => self.authorize());
        },
        get restore(): ApiResource<UserModel> {

          return new ApiResource(apiService, `admin/user/restore`, UserModel).authorize();
        }
      };
    }

    get adminStaffList() {
      return new ApiResource(this.apiService, `admin/staff/list`, UserModel).authorize();
    }

    get adminCompanyList() {
      return new ApiResource(this.apiService, `admin/company/list`, CompanyModel).authorize();
    }

    get company() {

        return new ApiResource(this.apiService, `company`, CompanyModel).authorize();
    }

    get masterUser(): ApiResource<UserModel> {

        return new ApiResource(this.apiService, `master`, UserModel).authorize();
    }

    get staff(): ApiResource<UserModel> {

        return new ApiResource(this.apiService, `staff`, UserModel).authorize();
    }

    get account(): ApiResource<AccountModel> {

        return new ApiResource(this.apiService, `account`, AccountModel).authorize();
    }

    get exportAccounts(): ApiResource<AccountModel> {

        return new ApiResource(this.apiService, `account/export/xlsx`, AccountModel).authorize();
    }

    get currency(): ApiResource<CurrencyModel> {

        return new ApiResource(this.apiService, `currency`, CurrencyModel).authorize();
    }

    get currencyRates(): ApiResource<any> {

        return new ApiResource(this.apiService, `/currency/rates`, CurrencyModel).authorize();
    }

    get selectedCurrency(): ApiResource<any> {

        return new ApiResource(this.apiService, `user/currency/selected`, CurrencyModel).authorize();
    }

    get setActiveCurrency(): ApiResource<any> {

        return new ApiResource(this.apiService, `user/currency/select`, CurrencyModel).authorize();
    }

    get userCompanies(): ApiResource<CompanyModel> {

        return new ApiResource(this.apiService, `user/companies`, CompanyModel).authorize();
    }

    get report() {

        return new ApiResource(this.apiService, `report`, ReportModel).authorize();
    }

    get reportShow() {

        return new ApiResource(this.apiService, `report/show`, ReportModel).authorize();
    }

    get reportAnnual() {

      return new ApiResource(this.apiService, `report/annual`, TotalReportModel).authorize();
    }

    get reportBalance() {

      return new ApiResource(this.apiService, `report/balance`, ReportModel).authorize();
    }

    get reportSummaryRecord() {

      return new ApiResource(this.apiService, `report/summary`, TotalReportModel).authorize();
    }

    get reportAdjustment() {
      return new ApiResource(this.apiService, `report/adjustment`, ReportModel).authorize();
    }

    constructor(protected apiService: ApiService) {
    }
}
