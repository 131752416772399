import { BehaviorSubject } from 'rxjs';
import { Fabricator } from './types';

export class LocalStorage<T> {

    subject: BehaviorSubject<T>;

    get value(): T {

        return this.get();
    }

    constructor(protected key: string, protected fabricator: Fabricator<T> = (stored?: any): T => stored || undefined) {

        this.get();
    }

    get(): T {

        if (this.subject === undefined) {

            const value = localStorage.getItem(this.key);

            if (value) {
                try {

                    this.subject = new BehaviorSubject<T>(this.fabricator(JSON.parse(value)));
                } catch (e) {

                    console.warn(`Storage '${this.key}' contains broken data`);
                }
            } else {

                this.subject = new BehaviorSubject<T>(this.fabricator());
            }
        }

        return this.subject.value;
    }

    set(value: T): void {

        localStorage.setItem(this.key, JSON.stringify(value));

        this.subject.next(value);
    }

    has(): boolean {

        return this.subject.value === this.fabricator();
    }

    unset(): void {

        localStorage.removeItem(this.key);

        this.subject.next(this.fabricator());
    }
}
