import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesRoutingModule } from './companies-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../../common/forms/forms.module';
import { AuthModule } from '../auth/auth.module';
import { ApiModule } from '../api/api.module';
import { UiModule } from '../ui/ui.module';
import { CompaniesComponent } from './companies.component';
import { CompanyItemComponent } from './company-item/company-item.component';
import { CompanyModifyComponent } from './company-modify/company-modify.component';
import { CompanyService } from './company.service';
import {
    MatAutocompleteModule, MatChipsModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule, MatTreeModule
} from '@angular/material';
import { TreeModule } from 'angular-tree-component';
import { CompaniesListComponent } from './companies-list/companies-list.component';

@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  AuthModule,
                  ApiModule,
                  CompaniesRoutingModule,
                  MatDialogModule,
                  MatListModule,
                  MatSelectModule,
                  UiModule,
                  MatProgressBarModule,
                  MatInputModule,
                  MatAutocompleteModule,
                  MatTreeModule,
                  MatChipsModule,
                  TreeModule.forRoot()
              ],
              providers: [
                  CompanyService
              ],
              declarations: [
                  CompaniesComponent,
                  CompaniesListComponent,
                  CompanyItemComponent,
                  CompanyModifyComponent,
              ],
              exports: [],
              entryComponents: [
                  CompanyModifyComponent,
              ]
          })
export class CompaniesModule {
}
