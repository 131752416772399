import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ReportModel, ReportType} from '../../models/report.model';
import {finalize} from 'rxjs/operators';
import {ApiResourcesService} from '../../api/api-resources.service';
import {Location} from '@angular/common';
import {CompanyModel} from '../../models/company.model';
import {ReportAccountModel} from '../../models/report.account.model';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-detail',
  templateUrl: './reports-detail.component.html',
  styleUrls: ['./reports-detail.component.scss']
})
export class ReportsDetailComponent implements OnInit, OnDestroy {

  private routeSub: Subscription;
  report: ReportModel;
  reportAccounts: Array<ReportAccountModel>;
  company: CompanyModel;
  progressBarMode: string;
  params: any;
  displayedColumns: string[] = [ 'code', 'name', 'type', 'pbc', 'adj', 'adjusted', 'balance' ];

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    protected apiResourcesService: ApiResourcesService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.params = params;
    });

    this.getReportData();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  getReportData() {
    this.progressBarMode = 'query';
    this.apiResourcesService.reportShow
        .whereId( this.params.date + '/' + this.params.companyId)
        //.whereId( moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.params.companyId)
      .where('type', ReportType.Json)
      .first()
      .pipe(finalize(() => this.progressBarMode = null))
      .subscribe((report: ReportModel) => {
        this.report = report;
        this.reportAccounts = this.report.accounts;
        this.company = this.report.company;
      });
  }

  goBack(): void {
    this.location.back();
  }

}
