import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class NotificationsService {

    constructor(protected snackBar: MatSnackBar) {
    }

    show(message: string, action = 'Close') {

        this.snackBar.open(message, action, {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }

    dismiss() {

        this.snackBar.dismiss();
    }
}
